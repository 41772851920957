




















































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { showSnackbar, showDialog } from "@/UIHelper";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import {
  calcFunctionNew,
  getAufgabeDefinition,
  getKZAufgabeDefiniton
} from "@/CalcHelperStatic";
import {
  getDisplay,
  getDisplayValue,
  getGroupSumValue,
  getKZColumn,
  getValue
} from "@/DataHelper";
import { getMonthNames, valueToFormatStr } from "@/editor/CalenderHelper";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import AUApi from "@/api";
import { keysIn } from "lodash";
interface GridDialogData {
  aic?: number;
  title: string;
  componentName: string;
  zeitbereich?: APIZeitbereich;
  showCustomEditor: boolean;
  showCustomGrid: boolean;
  query: string;
  lastValues?: null;
  newData: boolean;
  bewegungsdaten: boolean;
  width: number;
  height: number;
  fullscreen?: boolean;
  mainAic?: number;
  mainStt?: number;
  printItems?: any[];
}

@Component
export default class BaseVGrid extends Vue {
  private showLoading = true;
  private headers: DataTableHeader[] = [];
  public header: any = { bezeichnung: "hallo" };
  private bezeichnung: string = "";
  private noData = false;
  protected tableData: any[] = [];

  private visibleColumns: DataColumn[] = [];
  private colorColumn: DataColumn[] = [];
  private aggregateColumns: DataColumn[] = [];
  public allColumns: DataColumn[] = [];

  private showGroupBy = false;
  private groupedBy: string[] = [];
  private expanded: any[] = [];
  private showExpand: boolean = false;
  private showSearch: boolean = false;
  private search: string = "";
  private menuSearch: boolean = false;
  private sortDesc: string[] = [];
  private sortBy: string[] = [];
  private aic: number = 0;
  private rowKey = "aic_Stamm";
  private showZeitraum: boolean = false;
  private type: string = "month";
  private landscape: boolean = true;
  private menu: boolean = false;
  private date: string[] = [new Date().toISOString().substr(0, 7)];
  private dateString: string =
    getMonthNames()[new Date().getMonth()] + " " + new Date().getFullYear();
  private startVal: Date = this.$api.zeitbereich.von;
  private endVal: Date = this.$api.zeitbereich.bis;

  private frageDelete: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Loeschen2",
    "Soll der Datensatz wirklich gelöscht werden?"
  );
  private frageCopy: string = this.$globalsBegriffMemo(
    this.Globals.Begriff,
    "Kopie",
    "Soll der Datensatz kopiert werden?"
  );
  // private singleSelect: boolean = false;
  private showSelect: boolean = false;
  private selected: any[] = [];
  private pageSize = 15;
  private dialogEdit: boolean = false;
  private dialogInlineEdit: boolean = false;
  private dialogOpen: boolean = false;
  private dialogVerwendung: boolean = false;
  private showDialog: boolean = false;
  private showBezeichnung: boolean = true;
  private showCopy: boolean = false;
  private showCopyStamm: boolean = false;
  private showVerwendung: boolean = false;
  private calcModell: string = "";
  private subAbf: string = "";
  private editAbf: string = "";
  private aufgabenDefinition?: QueryResponse;
  private dialog: GridDialogData = {
    title: "--!!--",
    componentName: "None",
    showCustomEditor: false,
    showCustomGrid: false,
    query: "",
    newData: false,
    bewegungsdaten: false,
    width: 455,
    height: 200,
    fullscreen: false,
    mainAic: 0,
    mainStt: 0,
    printItems: []
  };
  @Prop() public queryData?: QueryResponse;
  @Prop() public query?: string;
  @Prop() public queryAic?: number;
  @Prop() public mainAic?: number;
  private subQuery?: string;
  private expandQuery?: string;
  @Prop() public preloadedData!: any;
  @Prop({ default: true }) public disableFooter!: boolean;
  @Prop() public dark?: boolean;
  @Prop() public showClose: boolean = false;
  @Prop({ default: null }) private zeitbereich!: APIZeitbereich | null;

  private mainAicInternal: number = 0;
  private lastQueryResponse?: QueryResponse;

  private maxWidth = window.innerWidth;
  private initialized = false;
  @Prop() protected printItems: PrintItems[] = [];
  private mainprintItems: PrintItems[] = [];
  @Prop() public mainStt: number = 0;
  private mainSttInternal: number = 0;
  private lokalZeitbereich = {
    von: this.$api.zeitbereich.von,
    bis: this.$api.zeitbereich.bis,
    bereich: "Tag"
  };
  public mounted() {
    getAufgabeDefinition().then((r: any) => {
      this.aufgabenDefinition = r;
    });
    this.mainAicInternal = this.mainAic ?? 0;
    this.mainSttInternal = this.mainStt ?? 0;
    this.refresh();
  }

  private get visibleColumnsWithoutGrouped() {
    const cols = this.visibleColumns.filter(
      c => this.groupedBy.indexOf(c.name) < 0
    );
    return cols;
  }
  public fixKennungForProperty(column: any): string {
    let x = "g" + column.name;
    if (this.allColumns) {
      x += "_" + this.allColumns.indexOf(column);
    }
    return x.replace(/\s+/g, "_");
  }
  @Watch("queryData")
  private async refresh() {
    try {
      if (this.preloadedData) {
        this.dataLoaded(this.preloadedData);
      } else if (this.queryData) {
        this.dataLoaded(this.queryData);
      } else if (this.query) {
        const response = await AUApi.Current.getQuery(
          this.query,
          undefined,
          this.queryAic,
          this.lokalZeitbereich
        );
        this.dataLoaded(response.data);
      }
    } finally {
      this.addDruck();
      this.showLoading = false;
    }
  }

  private expandDataSet?: QueryResponse | null = null;

  private async loadDetails(item: any) {
    const me = this;
    if (item.value) {
      this.showLoading = true;
      try {
        if (this.expandQuery) {
          this.showLoading = true;
          const itemLokal = item;
          const aic = itemLokal.item.aic_Stamm;
          this.printItems = [];
          this.mainAicInternal = aic ?? this.mainAic;
          this.mainSttInternal = me.header.aicStt; // mainStt = Stammtyp auf den gespeichert werden soll
          const response = await AUApi.Current.getQuery(
            this.expandQuery,
            undefined,
            aic,
            this.lokalZeitbereich
          );
          if (response && response.data) {
            const dataExpand = response.data;
            const colsExpand = dataExpand.columns.filter(
              c => !c.meta.invisible && !c.meta.banned
            );
            this.expandDataSet = dataExpand;
            // if (dataExpand) { this.isExpandDataSetLoaded = true; }
            // else { this.isExpandDataSetLoaded = false; }
            this.showLoading = false;
          }
          return "no data found";
        }
      } finally {
        this.showLoading = false;
      }
    }
  }

  private dialogDelete: boolean = false;
  private newItem() {
    if (this.subQuery) {
      this.dialog.query = this.subQuery;
      const me = this;
      if (me.header.aicBew) {
        // this.dialog.aic = this.mainAicInternal;
        this.dialog.aic = undefined;
        this.dialog.mainAic = me.mainAicInternal;
        this.dialog.mainStt = me.mainSttInternal;
        this.dialog.bewegungsdaten = false;
        // wenn es um SubData geht - dann muss ich den führenden AIC übergeben!
      } else {
        this.dialog.aic = undefined;
      }
      this.dialog.newData = true;
      this.dialogOpen = true;
    } else if (this.dialog.showCustomEditor) {
      onShowSite({
        title: this.dialog.title,
        site: this.dialog.componentName,
        data: {
          zeitbereich: this.$api.zeitbereich,
          aic: this.dialog.aic,
        },
        callOnLeave: () => {
          setTimeout(() => refreshFullPageContent());
          // return true;
        }
      });
    }
  }
  private editItem(item: any) {
    debugger;
    this.dialog.query = this.editAbf;
    this.dialog.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    if (item.aic_Bew_pool) {
      this.dialog.bewegungsdaten = true;
    }
    this.dialog.newData = false;
    if (this.dialog.query) {
      this.dialogOpen = true;
    } else {
      onShowSite({
        title: this.dialog.title,
        site: this.dialog.componentName,
        data: {
          zeitbereich: this.$api.zeitbereich,
          aic: this.dialog.aic,
        }
      });
    }
  }
  private deleteItemDialog(item: any) {
    this.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    this.dialogDelete = true;
  }
  private dialogCopy: boolean = false;
  private copyItemDialog(item: any) {
    this.aic = item.aic_Stamm ?? item.aic_Bew_pool;
    this.dialogCopy = true;
  }
  private deleteItem() {
    this.$api.DeleteData(
      this.header,
      this.aic,
      0,
      this.zeitbereich,
      (res: any) => {
        const msgType = res.data.msgType;
        let info = res.data.info;
        const tabInfo = res.data.tab;
        const memo = res.data.memo;
        const title = res.data.titel;
        if (memo) {
          info = memo;
        }
        if (tabInfo) {
          const typ = tabInfo[0].Bewegungstyp;
          const anzahl = tabInfo[0].Anzahl;
          info =
            info +
            "<br><br> in Tabelle: " +
            typ +
            "<br> Anzahl Verwendung: " +
            anzahl;
        }
        if (info) {
          if (msgType === "Error") {
            showSnackbar({
              text: info,
              color: "error"
            });
          } else if (msgType === "Info") {
            showSnackbar({
              text: info,
              color: "info"
            });
          } else if (msgType === "Warnung") {
            showSnackbar({
              text: info,
              color: "warning"
            });
          }
          this.showLoading = false;
          return msgType;
        }
        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_geloescht",
            "Daten wurden gelöscht"
          )
        );
      },
      (ex: any) => {
        let errorMessage = "";
        if (ex.response) {
          errorMessage = ex.response.data.error;
        } else {
          errorMessage = ex;
        }
        this.$root.$emit("alert", {
          text: errorMessage,
          type: "error"
        });
        console.log(ex);
        alert("Entschuldigung es ist ein Fehler beim Löschen aufgetreten");
      }
    );
    this.dialogDelete = false;
    this.showLoading = true;
    this.deleteRow();
    // this.refresh();
  }

  private copyItem() {
    const me = this;
    const varUbergabe: any[] = [];
    calcFunctionNew(this.calcModell, this.aic, false, me, "", varUbergabe)
      ?.then((response: any) => {
        if (response !== "Error") {
          this.onBack(response);
        }
      })
      .catch((error: any) => {
        // hübsche Fehlerbehandlung
        showSnackbar({
          text: error,
          color: "error"
        });
        console.log("ModellError:" + error);
      });
    this.dialogCopy = false;
  }
  private async copyStamm() {
    const me = this;
    const response = await this.$api.copyStamm(this.aic);
    this.dialogCopy = false;
    if (response?.data.copyStamm) {
      const bezeichnung = response.data.copyBez;
      this.refresh();
    }
    // function von Roland!
  }
  private closeDelete() {
    this.dialogDelete = false;
  }
  private closeCopy() {
    this.dialogCopy = false;
  }
  protected onCancel() {
    this.dialogVerwendung = false;
  }
  private openVerwendung(item: any) {
    const me = this;
    this.dialog.query = this.subAbf;
    this.dialog.aic = undefined;
    this.dialog.mainAic = item.aic_Stamm;
    this.dialog.mainStt = 0;
    this.dialog.bewegungsdaten = true;
    this.dialogVerwendung = true;
    this.dialog.printItems = [];
    this.dialog.width = innerWidth;
    this.dialog.height = 800;
  }
  public dataLoaded(data: QueryResponse) {
    try {
      this.lastQueryResponse = data;
      if ((data?.data?.length ?? 0) === 0) {
        this.header = data.header;
        this.noData = true;
        this.mainStt = data.header.aicStt;
        if (this.header.aicBew && this.header.keinZeitraum !== true) {
          this.showZeitraum = true;
        }
        if (this.header.keinBez) {
          this.header = "";
          this.bezeichnung = "";
          this.showBezeichnung = false;
        } else {
          this.bezeichnung = this.header.bezeichnung;
        }
        if (this.header?.DialogEdit) {
          const abfDetails = this.header.abfDetail;
          if (abfDetails) {
            this.subQuery = abfDetails;
          }
          this.dialogEdit = true;
        }
        return;
      }

      if (data.data[0] && data.data[0].aic) {
        this.rowKey = "aic";
      } else if (data.data[0] && data.data[0].aic_Stamm) {
        this.rowKey = "aic_Stamm";
      } else if (data.data[0] && data.data[0].aic_Bew_pool) {
        this.rowKey = "aic_Bew_pool";
      }
      this.header = data.header;
      const details = this.header.abfDetail;
      if (details) {
        this.subQuery = details;
        this.dialog.query = details;
      }
      const webStamm = this.header.webStamm; // webStamm = definitin bei der Abfrage!
      if (webStamm) {
        if (this.aufgabenDefinition) {
          const stamm = this.aufgabenDefinition?.data.find(
            (r: any) => r.aic_Stamm === webStamm
          );
          if (stamm) {
            this.showCopyStamm = getKZAufgabeDefiniton(
              "stammKopie",
              stamm.aic_Stamm,
              this.aufgabenDefinition
            );
          }
        }
      }
      const erweitert = this.header.abfErweitert;
      if (erweitert) {
        this.expandQuery = erweitert;
      }
      if (this.expandQuery) {
        this.showExpand = true;
      }
      this.showSearch = this.header.suche;
      this.bezeichnung = this.header.bezeichnung;
      if (this.header.keinBez) {
        this.header = "";
        this.bezeichnung = "";
        this.showBezeichnung = false;
      }
      this.allColumns = data.columns;
      const dataTable = data.data;

      const cols = data.columns.filter(
        c => !c.meta.invisible && !c.meta.banned
      );
      this.visibleColumns = cols;
      const sysTable = data.columns.filter(c => c.datatype === "SysAic");
      if (this.header?.DialogEdit) {
        this.showDialog = true;
        this.dialogInlineEdit = true;
        this.dialogEdit = true;
      }

      this.headers = cols.map(c => {
        this.showGroupBy = this.showGroupBy || c.meta.gruppierbar === true;
        return {
          text: c.title,
          value: c.name,
          align: "start",
          queryColumn: c,
          groupable: c.meta.gruppierbar === true && c.meta.gruppiert === true
        };
      });
      if (this.dialogInlineEdit) {
        this.headers.push({
          text: "Actions",
          value: "action",
          sortable: false
        });
      }
      const calcModell: any = data.columns.filter(c => c.datatype === "Modell");
      if (calcModell!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "Actions",
            value: "action",
            sortable: false
          });
        }
        this.showCopy = true;
        const subMod = calcModell[0].meta;
        this.calcModell = subMod.subMod.kennung;
      }
      const subAbf: any = data.columns.filter(
        c => c.datatype === "Abfrage" && c.meta.kz === "use"
      );
      if (subAbf!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "Actions",
            value: "action",
            sortable: false
          });
        }
        this.showVerwendung = true;
        const subAbfDetail = subAbf[0].meta;
        this.subAbf = subAbfDetail.subAbf.kennung;
      }
      const editForm: any = data.columns.filter(
        c => c.datatype === "Formular" && c.meta.kz === "edit"
      );
      if (editForm!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "Actions",
            value: "action",
            sortable: false
          });
        }
        // this.showVerwendung = true;
        const editDetail = editForm[0].meta;
        this.dialog.componentName =
          editDetail.subFom.alias ?? editDetail.subFom.kennung;
        this.dialog.title = editDetail.subFom.bezeichnung;
        this.dialog.showCustomEditor = true;
        this.dialog.fullscreen = true;
        this.dialog.width = window.innerWidth;
        this.dialog.height = window.innerHeight;
      }
      const editAbf: any = data.columns.filter(
        c => c.datatype === "Abfrage" && c.meta.kz === "edit"
      );
      if (editAbf!.length > 0) {
        if (!this.dialogInlineEdit) {
          this.headers.push({
            text: "Actions",
            value: "action",
            sortable: false
          });
        }
        this.showVerwendung = true;
        const editAbfDetail = editAbf[0].meta;
        this.editAbf = editAbfDetail.subAbf.kennung;
      } else {
        if (this.subQuery) {
          this.editAbf = this.subQuery;
        }
      }
      this.colorColumn = getKZColumn("color", data.columns);
      this.aggregateColumns = data.columns.filter(
        (c: any) =>
          c.meta.ergebnisArt !== undefined && c.meta.ergebnisArt !== null
      );

      this.groupedBy = data.columns
        .filter(c => c.meta.gruppierbar === true && c.meta.gruppiert === true)
        .map(c => c.name);

      // hier nur dateninhalte kein styling
      // this.tableData = data.data;
      this.tableData = data.data.map(row => {
        const tr: any = {};
        // let alignText: any = "Right";
        // let widthNumber = 200;
        // if (row.meta.width || row.meta.webWidth) {
        //   widthNumber = row.meta.webWidth ?? row.meta.width ?? 150 * 1.45;
        // }
        // if (
        //   row.type === "textField" ||
        //   row.type === "comboBox" ||
        //   row.type === "datePicker" ||
        //   row.type === "checkBox3"
        // ) {
        //   alignText = "Left";
        // }
        this.visibleColumnsWithoutGrouped.forEach(col => {
          tr[col.name] = getDisplay(row, col);
        });
        sysTable.forEach(col => {
          tr[col.name] = getDisplay(row, col);
        });
        return tr;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.showLoading = false;
      this.initialized = true;
    }
  }
  public itemRowBackground(item: any) {
    // paramaterFarbe übergeben
    if (item.colorCloumn) {
      return item.colorColumn;
    }
  }

  public getRowStyle(rowIndex: number) {
    if (!this.colorColumn) {
      return;
    }
    const row = this.lastQueryResponse?.data[rowIndex];
    const color = getValue(row, this.colorColumn);
    return { backgroundColor: color };
  }

  public getDisplay(row: any, column: DataColumn) {
    return getDisplay(row, column);
  }

  private groupChanged(val: string[]) {
    this.groupedBy = val;
  }
  public getFooter(col: DataColumn, options: any, pagination: any) {
    if (
      !this.lastQueryResponse ||
      !this.aggregateColumns.find(c => c === col)
    ) {
      return;
    }

    const val = getGroupSumValue(this.lastQueryResponse.data, col);
    return getDisplayValue(val, col);
  }
  private onDialogClose() {
    this.dialogOpen = false;
  }
  private onBack(updateData: any[]) {
    if (updateData) {
      let newData = false;
      const data = updateData[0];
      if (!this.header.aicBew) {
        let gridrow = this.tableData.find(
          (row: any) => row.aic_Stamm === data.aic_Stamm
        );
        if (!gridrow) {
          gridrow = data;
          newData = true;
        }
        if (data) {
          for (const key of Object.keys(data)) {
            if (key !== "aic_Stamm") {
              const column = this.allColumns.find(
                (col: any) => col.name === key
              );
              if (column) {
                try {
                  const rowProp: any = column.name;
                  gridrow[rowProp] = getDisplayValue(data[key], column);
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }
          if (newData) {
            this.tableData.push(gridrow);
            this.sortData();
          }
          this.tableData = [...this.tableData]; // datasource refreshen für grid
        }
      } else {
        let gridrowBew = this.tableData.find(
          (row: any) => row.aic_Bew_pool === data.aic_Bew_pool
        );
        if (!gridrowBew) {
          gridrowBew = updateData[0];
          newData = true;
        }
        if (data) {
          for (const key of Object.keys(data)) {
            if (key !== "aic_Bew_pool") {
              const column = this.allColumns.find(
                (col: any) => col.name === key
              );
              if (column) {
                try {
                  const rowProp: any = column.name;
                  gridrowBew[rowProp] = getDisplayValue(data[key], column);
                } catch (ex) {
                  console.log(ex);
                }
              }
            }
          }
          if (newData) {
            this.tableData.push(gridrowBew);
            this.sortData();
          }
          this.tableData = [...this.tableData]; // datasource refreshen für grid
        }
      }
    }
    this.dialogOpen = false;
  }
  private dialogBack() {
    this.dialogVerwendung = false;
  }

  private sortData() {
    const sort = this.allColumns.find((col: any) => col.meta.sortiert === 1);
    const sort2 = this.allColumns.find((col: any) => col.meta.sortiert === 2);
    if (sort2) {
      this.tableData.sort(this.dynamicSort(sort2.name));
    }
    if (sort) {
      this.tableData.sort(this.dynamicSort(sort.name));
    }
  }

  private deleteRow() {
    let gridrow;
    if (this.header.aicBew) {
      gridrow = this.tableData.findIndex(
        (row: any) => row.aic_Bew_pool === this.aic
      );
    } else {
      gridrow = this.tableData.findIndex((i: any) => i.aic_Stamm === this.aic);
    }
    this.aic = 0;
    if (gridrow !== -1) {
      this.tableData.splice(gridrow, 1);
      this.tableData = [...this.tableData]; // datasource refreshen für grid
    }
    this.showLoading = false;
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  protected createdBase() {
    const me = this;
    this.$root.$on("windowResize", (a: any) => me.onResize(a));
  }
  public onResize(args: any) {
    this.$forceUpdate();
  }
  private addDruck() {
    if (!this.header.aicBew) {
      const stamm = this.header.aicStt;
      // const stammTyp = this.$globalsStt(Globals.Stt, "", stamm);
      this.mainprintItems = this.$globalsDruck("Begriff", false, stamm, true);
      if (this.mainprintItems.length > 0) {
        this.showSelect = true;
      }
    }
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let fullscreen = false;
    const vecStamm: any[] = [];
    if (this.selected.length > 0) {
      this.selected.forEach(element => {
        vecStamm.push(element.aic_Stamm);
      });
    }
    if (this.selected.length === 0) {
      showSnackbar({
        text: "Bitte wählen Sie zuerst einen Datensatz aus!",
        color: "warning"
      });
      return;
    }
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      fullscreen = true;
    }
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      fullscreen,
      width: 510,
      data: { zeitbereich: this.$api.zeitbereich, printItem, vecStamm },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        /* nix tun*/
      }
    });
  }
  private onMonthChanged(args: any) {
    this.menu = false;
    this.date = args;
    const argsDate = new Date(args[0]);
    this.dateString =
      getMonthNames()[argsDate.getMonth()] + " " + argsDate.getFullYear();
    if (!args) {
      return;
    }
    let vonDate: Date | undefined;
    let bisDate: Date | undefined;
    debugger;
    if (this.date?.length > 0) {
      const vals = this.date.map(d => new Date(d).valueOf());

      vonDate = new Date(Math.min(...vals));
      bisDate = new Date(Math.max(...vals)).lastInMonth();
      this.dateString =
        getMonthNames()[vonDate.getMonth()] +
        " - " +
        getMonthNames()[bisDate.getMonth()] +
        " " +
        bisDate.getFullYear();
    } else {
      vonDate = new Date(args);
      bisDate = new Date(new Date(vonDate).lastInMonth());
    }
    if (vonDate && bisDate) {
      this.lokalZeitbereich = {
        bereich: "Tag",
        von: vonDate,
        bis: bisDate
      };
      this.tableData = [];
      this.refresh();
    }
  }
}
