


















































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { showSnackbar } from "@/UIHelper";
import BaseEditor from "@/components/BaseEditor.vue";
@Component
export default class Benutzer extends Vue {
  private zeitFormat: boolean = false;
  private showLoading: boolean = false;
  private infoData: any;
  private data: any;
  private user: User[] = [];

  private showDelete: boolean = false;
  private columns: DataColumn[] = [];
  private selected: User[] = [];

  private selectedUser: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };

  public async mounted() {
    const me = this;
    me.showLoading = true;
    // me.$api.getBenutzergruppen();
    try {
      const userData = await me.$api.getUser(0, 0);
      if (userData) {
        this.user = userData.data.data;
        this.columns = userData.data.columns;
      }
      this.showLoading = false;
    } catch (ex) {
      this.showLoading = false;
      // error ausgeben...
    }
  }
  private async deleteUser() {
    try {
      this.$api.deleteUser(this.selectedUser.aic).then((res: any) => {
        const meldung = res.data.info;
        if (meldung) {
          showSnackbar({
            text: meldung,
            color: "info"
          });
        }
      });
      const userData = await this.$api.getUser(0, 0);
      if (userData) {
        this.user = userData.data.data;
        this.$forceUpdate();
      }

      this.initUser(false);
    } catch {
      // Meldung
    }
  }
  private initUser(kopie: boolean) {
    if (!kopie) {
      this.selected = [];
      this.selectedUser = "";
    }
  }
  private async neuAnlageUser(kopie: boolean) {
    let aic = 0;
    let copyAic = 0;
    if (kopie) {
      copyAic = this.selectedUser.aic;
    } else {
      aic = this.selectedUser.aic;
    }
    this.$api
      .newUser(
        aic,
        this.selectedUser.kennung,
        this.selectedUser.bezeichnung,
        2,
        this.selectedUser.tel,
        this.selectedUser.eMail,
        this.selectedUser.sprache.aic,
        this.selectedUser.land.aic,
        this.selectedUser.person.aic,
        this.selectedUser.hauptBenutzerGruppe.aic,
        copyAic
      )
      .then((res: any) => {
        const changed = res.data.aic;
        const fehler = res.data.info;
        if (fehler) {
          showSnackbar({
            text: fehler,
            color: "error"
          });
        } else if (changed) {
          showSnackbar({
            text: "Benutzer wurde erfolgreich angelegt",
            color: "info"
          });
        }
      });
    if (this.selectedUser) {
      const userData = await this.$api.getUser(0, 0);
      if (userData) {
        this.user = userData.data.data;
        this.$forceUpdate();
      }
    }
  }

  protected selectedUserChanged(args: User) {
    const user: any = this.user.find((m: any) => m.bezeichnung === args);
    this.selected = user;
    this.selectedUser = user;
    // let editor: BaseEditor | undefined;
    // const x = this.$refs.userEdit as Vue[];
    // if (x?.length > 0) {
    //   editor = x[0] as BaseEditor;
    // }
    // editor?.reloadData();
    const y: any = this.$refs.userEdit;
    y?.reloadData();

    this.showDelete = true;
  }
  private filterFunction(
    item: any,
    queryText: string,
    itemText: string
  ): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (
        itemText &&
        itemText.toLowerCase &&
        itemText.toLowerCase() + ""
      ).startsWith(queryText)
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) =>
          t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }
}
declare interface User {
  aic: number;
  kennung: string;
  bezeichnung: string;
  admin: boolean;
  art: number;
  datum: string;
  ebene: string;
  tel: string;
  ohne: boolean;
  pwMG: number;
  pwML: number;
  pwMM: number;
  pwMS: number;
  pwMZ: number;
}
