


























































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { showSnackbar } from "@/UIHelper";
import BaseEditor from "@/components/BaseEditor.vue";
import { User } from "@/components/EditPage";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";
import EditTextField from "../../editor/EditTextField.vue";
@Component
export default class BenutzerMitarbeiter extends EditPage {
  constructor() {
    super();
    this.loadUser = true;
  }
  private zeitFormat: boolean = false;
  private infoData: any;
  // @Prop() protected stammsatz!: number;
  @Prop() protected stammBezeichnung!: string;
  @Prop() protected stammKennung!: string;

  private bezeichnung: string = "";
  private kennung: string = "";
  private avatar: string = "";
  private text: string = "Benutzer: ";
  private initalized: boolean = false;

  private showDelete: boolean = false;

  public initialize() {
    if (this.showKopie) {
      this.kennung = this.stammKennung;
      this.bezeichnung = this.stammBezeichnung;
      this.text = "Vorlage wählen: ";
    }
    if (
      (this.selectedUser?.length === 0 || !this.selectedUser) &&
      this.initialized
    ) {
      this.selectedUser = this.data;
    }
    if (!this.showKopie) {
      const avatarData = getKennungValueDisplay(
        this.data,
        "Bild",
        this.columns
      );
      if (avatarData) {
        if (avatarData.data) {
          this.avatar = avatarData.data;
        } else {
          this.loadImage(avatarData);
          this.avatar = this.avatarIcon;
        }
      }
    }

    this.initalized = true;
    this.initialized = true;
  }
  private async deleteUser() {
    try {
      this.$api.deleteUser(this.selectedUser.aic).then((res: any) => {
        const meldung = res.data.info;
        if (meldung) {
          showSnackbar({
            text: meldung,
            color: "info"
          });
        }
      });
      const userData = await this.$api.getUser(0, 0);
      if (userData) {
        this.data = userData.data.data;
        this.$forceUpdate();
      }

      this.initUser(false);
    } catch {
      // Meldung
    }
  }
  private initUser(kopie: boolean) {
    if (!kopie) {
      this.selectedUser = [];
    }
  }
  private async neuAnlageUser(kopie: boolean) {
    let aic = 0;
    let copyAic = 0;
    if (kopie) {
      copyAic = this.selectedUser.aic;
    } else {
      aic = this.selectedUser.aic;
    }
    this.$api
      .newUser(
        aic,
        this.kennung,
        this.bezeichnung,
        2,
        "",
        "",
        this.selectedUser.sprache.aic,
        this.selectedUser.land.aic,
        this.stammsatz,
        0,
        copyAic
      )
      .then((res: any) => {
        const changed = res.data.aic;
        const fehler = res.data.info;
        if (fehler) {
          showSnackbar({
            text: fehler,
            color: "error"
          });
        } else if (changed) {
          showSnackbar({
            text: this.$globalsBegriffMemo(
            this.Globals.Begriff,
            "Benutzer_anlegen",
            "Benutzer wurde erfolgreich angelegt!"
          ),
            color: "info"
          });
          this.showKopie = false;
          this.text = "Benutzer";
          this.initialized = false;
          this.selectedUser = [];
          this.reloadData();
          if (this.initialized) {
            this.initialize();
          }
        }
      });
  }
  private onRefresh() {
    // (this.$refs.benutzer as any).onRefresh();
    this.$forceUpdate();
  }
  protected selectedUserChanged(args: User) {
    const user: any = this.user.find((m: any) => m.bezeichnung === args);
    this.selectedUser = user;
    this.data = user;
    if (this.showKopie) {
      this.kennung = this.stammKennung;
      this.bezeichnung = this.stammBezeichnung;
    }
    this.initialized = false;
    this.initialize();
  }
  private filterFunction(
    item: any,
    queryText: string,
    itemText: string
  ): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (
        itemText &&
        itemText.toLowerCase &&
        itemText.toLowerCase() + ""
      ).startsWith(queryText)
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) =>
          t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }
  private resetPassword() {
    this.$api.resetPassword(this.selectedUser.kennung).then((res: any) => {
      const fehler = res.data.info;
      if (fehler) {
        showSnackbar({
          text: fehler,
          color: "error"
        });
      } else {
        showSnackbar({
         text: this.$globalsBegriffMemo(
            this.Globals.Begriff,
            "Web_PW_zurueckgesetzt",
            "Passwort wurde erfolgreich zurückgesetzt!"
          ),
          color: "info"
        });

      }
    });
  }
}
