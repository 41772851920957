import { Globals, StaticGlobals } from "./Globals";
import { formatDate } from "./editor/CalenderHelper";

// übergibt die Darstellung wenn NICHT editierbar - auch für BaseEditor
export function zeroPad(num: number, places: number) {
  return String(num).padStart(places, "0");
}
export function getValue(row: any, column: any, getAic?: boolean, getKennung?: boolean) {
  if (!column) {
    console.warn("no column given!");
    return;
  }
  let value = null;
  if (row) {
    value = row[column.name];
  }
  if (value === undefined || value === null) {
    return null;
  }
  let tempValue: number;
  if (typeof value === "object") {
    if (column.type === "comboBox" || column.type === "hierarchie" || column.type === "choiceBox") {
      if (getKennung) {
        tempValue = value.kennung;
      } else {
        tempValue = value.aic;
      }
    } else if (column.type === "bild") {
      tempValue = value.data;
    } else if (column.type === "gps") {
        tempValue = value;
    } else {
      tempValue = value.value;
      if (value.faktor > 0) {
        tempValue = value.value / value.faktor;
      }
    }
  } else if (column.type === "ampel") {
    const ampelDaten = findAmpelNumber(value);
    tempValue = ampelDaten.nr;
  } else if (column.type === "checkBox3") {
    if (!column.meta.combo) {
      column.meta.combo = [
        { aic: 0, nr: 0, bezeichnung: "-" },
        { aic: 1, nr: 1, bezeichnung: "Ja" },
        { aic: 2, nr: 2, bezeichnung: "Nein" }
      ];
    }
    const wert = value;
    const element = column.meta.combo.find((f: any) => f.aic === wert);
    if (element) {
      if (getAic) {
        return element.aic;
      } else { return element.nr; }
    } else {
      return 0;
    }
  } else {
    tempValue = value;
  }

  return tempValue;
}
export function getDisplay(row: any, column: DataColumn) {
  if (!column) {
    console.warn("no column given!");
    return;
  }

  let value = null;
  if (row) {
    value = row[column.name];
  }
  return getDisplayValue(value, column);
}
export function getDisplayValue(value: any, column: any) {
  // if (typeof value === "object") {}
  if (value === undefined || value === null) {
    if (column.type === "mass") {
      if (column.meta.HHmm) {
        return "00:00";
      } else {
        return 0;
      }
    } else if (column.type === "waehrung") {
      let txt = "";
      const keineEinheit = column.meta.keineEinheit;
      if (!keineEinheit && !column.meta.originalEinheit && column.meta.einheit) {
        const einheit = column.meta.einheit;
        const anzeige = StaticGlobals.Current.globalsAic(
          Globals.Einheit,
          einheit
        );
        if (anzeige && anzeige.kennung) {
          txt = anzeige.bezeichnung;
        }
      }
      if (column.meta.format) {
        return column.meta.format;
      } else {
        txt = "0.00 " + txt;
        return txt;
      }
    } else {
      return null;
    }
  }
  let date = null;

  if (column.type === "vonBis") {
    if (!value) {
      return "";
    }
    let text = "";
    if (value.von && value.bis) {
      text +=
        formatDate(new Date(value.von), column.meta.format) +
        " - " +
        formatDate(new Date(value.bis), column.meta.format);
    } else if (value.von && !value.bis) {
      text +=
        formatDate(new Date(value.von), column.meta.format) +
        "- ";
    } else if (!value.von && value.bis) {
      text +=
        "-> " +
        formatDate(new Date(value.bis), column.meta.format);
    }
    if (value.von && value.bis) {
      const dec = value.dauer / value.faktor;
      text += " " + dec.toFixed(2);
      if (value.kz) {
        text += " " + value.kz;
      }
    }
    if (text === "") {
      if (column.meta.required) {
        text = "-!!-";
      } else {
        text = "--";
      }
    }

    return text.trim();
  }
  // Prüfung auf Sprache fehlt noch
  if (column.type === "checkBox") {
    if (value && value !== "false") {
      return "ja";
    } else {
      return "nein";
    }
  }
  if (column.type === "checkBox3") {
    // mit fallback befüllen wenn nicht da
    if (!column.meta.combo) {
      column.meta.combo = [
        { aic: 0, nr: 0, bezeichnung: "-" },
        { aic: 1, nr: 1, bezeichnung: "Ja" },
        { aic: 2, nr: 2, bezeichnung: "Nein" }
      ];
    }
    const element = column.meta.combo.find((f: any) => f.aic === value);
    if (element) {
      return element.bezeichnung;
    } else {
      return "???";
    }
  }

  // Zahlen auf 2 Nachkommastellen formatieren
  if (column.type === "zahl" || column.type === "mass") {
    let format: string = column.meta.format;
    let txt = "";
    // bei Originaleinheit kommt value als Object
    // die Formatierung steht dann in jeder zeile!
    if (typeof value === "object") {
      let tempValue = value.value;
      if (value.faktor > 0) {
        tempValue = value.value / value.faktor;
      }
      if (column.meta.HHmm && !column.meta.originalEinheit) {
        // bei Originaleinheit darf ich diese HH:mm Einstellung NICHT nehmen!
        if (tempValue && tempValue !== 0) {
          let valueNegativ = false;
          if (tempValue < 0) {
            tempValue = tempValue * -1;
            valueNegativ = true;
          }
          const hour = Math.floor(tempValue);
          const min = Math.round((tempValue - hour) * 60);

          if (valueNegativ) {
            txt =
              "-" +
              zeroPad(hour, 2) +
              ":" +
              zeroPad(min, 2);
            format = "HH:mm";
          } else {
            txt =
              zeroPad(hour, 2) + ":" + zeroPad(min, 2);
            format = "HH:mm";
          }
        } else {
          txt = "00:00";
        }
      } else if (format) {
        format = column.meta.format;
        if (format === "#") {
          txt = Number.parseFloat(tempValue).toFixed(0);
        } else if (format === "0.00") {
          txt = tempValue.toFixed(2);
        } else if (format === "00.00") {
          txt = tempValue.toFixed(2, { minimumFractionDigits: 2 });
        } else if (format === "00.0000") {
          txt = Number.parseFloat(tempValue).toFixed(4);
        } else {
          tempValue = Number.parseFloat(tempValue);
          txt = numberToStringWithThousends(tempValue, 2);
        }
      } else {
        if (tempValue !== 0) {
          tempValue = Number.parseFloat(tempValue);
          txt = numberToStringWithThousends(tempValue, 2);
        } else { txt = "0,00"; }
      }
      if (!column.meta.keineEinheit && value.kz) {
        txt = txt + " " + value.kz;
      } else if (!column.meta.keineEinheit && column.meta.einheit) {
        const einheit = column.meta.einheit;
        const anzeige = StaticGlobals.Current.globalsAic(
          Globals.Einheit,
          einheit
        );
        if (anzeige && anzeige.kennung) {
          txt = txt + " " + anzeige.bezeichnung;
        }
      }
      // txt = txt + " " + value.kz;
    } else {
      if (column.meta.HHmm && !column.meta.originalEinheit) {
        txt = value;
      } else if (format) {
        format = column.meta.format;
        if (format === "#") {
          txt = Number.parseFloat(value).toFixed(0);
        } else if (format === "0.00") {
          txt = value.toFixed(2, { minimumFractionDigits: 2 });
        } else if (format === "00.00") {
          txt = value.toFixed(2, { minimumFractionDigits: 2 });
        } else if (format === "00.0000") {
          txt = Number.parseFloat(value).toFixed(4);
        }
      } else {
        const tempValue = Number.parseFloat(value);
        if (tempValue !== 0) {
          txt = numberToStringWithThousends(tempValue);
        } else { txt = "0,00"; }
      }
    }
    // ist der Parameter keine Einheit gesetzt, dann soll der Text nicht dazugeschrieben werden
    const keineEinheit = column.meta.keineEinheit;
    if (!keineEinheit && !column.meta.originalEinheit && column.meta.einheit) {
      const einheit = column.meta.einheit;
      const anzeige = StaticGlobals.Current.globalsAic(
        Globals.Einheit,
        einheit
      );
      if (anzeige && anzeige.Kennung) {
        txt = txt + " " + getEinheitFromColumn(column).Kennung;
      }
    }
    return txt;
  }

  if (typeof value === "object") {
    if (value.bezeichnung !== undefined) {
      return value.bezeichnung;
    }
    if (value.faktor !== undefined && value.value !== undefined) {
      if (value.faktor > 0) {
        const dec = value.value / value.faktor;
        let txt: string = "";

        txt += " " + dec.toFixed(2);
        if (value.faktor === 3600) {
          txt += " h";
        }
        if (value.faktor === 60) {
          txt += " min";
        }
        return txt;
      }
      date = new Date(1970, 0, 1); // Epoch
      date.setSeconds(value.value);

      const hour24 = ("00" + date.getHours()).substr(-2);
      const min = ("00" + date.getMinutes()).substr(-2);
      const sec = ("00" + date.getSeconds()).substr(-2);
      let text = "";
      if (hour24 !== "00") {
        text += hour24 + ":";
      }
      text += min + ":" + sec;
      return text;
    }
  }

  // wie setze ich die background Color für die Row?
  if (column.type === "choiceColor") {
    const color: any = "";
    if (column.meta.colorRow) {
      return color.decimalColorToHTMLcolor(value);
    }
  }
  // Ampel -

  if (column.type === "ampel") {
    const ampelDaten = findAmpelNumber(value);
    const ampelnummer = ampelDaten.nr;
    return (
      "<img src='/" + ampelnummer + "_Ampel.png' alt='" + ampelnummer + "' />"
    );
  }

  if (column.type.indexOf("date") >= 0) {
    // auf type geändert - da es auch Ein und Austritt etc gibt..
    date = new Date(value);
    value = date.toLocaleDateString();
    if (column.meta !== undefined && column.meta.format !== undefined) {
      return formatDate(date, column.meta.format);
    }
  }
  if (column.type.indexOf("time") >= 0) {
    date = new Date(value);
    value = date.toLocaleDateString();
    if (column.meta !== undefined && column.meta.format !== undefined) {
      return formatDate(date, column.meta.format);
    }
  }

  if (column.datatype === "Formular") {
    value = "Details";
  }
  if (column.datatype === "GPS") {
    const latlong = new Array(value.lat, value.lng);
    value = latlong;
  }
  if (column.type === "waehrung") {
    const formatW = column.meta.format;
    let txtW = "";
    const tempValueW = value;
    if (formatW) {
      // format = column.meta.format;
      const float = Number.parseFloat(tempValueW);
      if (isNaN(tempValueW)) { debugger; }
      if (formatW === "#") {
        txtW = numberToStringWithThousends(float, 0);
      } else if (formatW === "0.00") {
        txtW = numberToStringWithThousends(float, 2);
      } else if (formatW === "00.00") {
        txtW = numberToStringWithThousends(float, 2);
      } else if (formatW === "00.0000") {
        txtW = numberToStringWithThousends(float, 4);
      } else if (formatW === "#,##0.00") {
        txtW = numberToStringWithThousends(float, 2); // Number.parseFloat(tempValueW).toFixed(2);
      }
    } else {
      if (value !== 0) {
        txtW = numberToStringWithThousends(tempValueW);
      } else { txtW = "0,00"; }
    }
    const noEinheit = column.meta.keineEinheit;
    if (!noEinheit && column.meta.einheit) {
      const einheitW = column.meta.einheit;
      const anzeigeW = StaticGlobals.Current.globalsAic(
        Globals.Einheit,
        einheitW
      );
      if (anzeigeW && anzeigeW.bezeichnung) {
        txtW = txtW + " " + anzeigeW.bezeichnung;
      }
    }
    return txtW;
  }
  return value;
}

export function getEinheitFromColumn(column: DataColumn) {
  const einheit = column.meta.einheit;
  if (einheit) {
    const anzeige = StaticGlobals.Current.globalsAic(
      Globals.Einheit,
      einheit
    );
    return anzeige;
  }
  return "";
}

export function getKennungValueDisplay(data: any, kennung: string, column: any) {
  if (!kennung) {
    return "-!-";
  }
  const col = column.find(
    (c: any) =>
      c.kennung && fixKennungForProperty(c.kennung) === kennung
  );
  if (!col) {
    return "";
  }
  const val = getDisplay(data, col);
  return val;
}
export function getKZValueDisplay(data: any, kz: string, column: any) {
  if (!kz) {
    return "-!-";
  }

  const col = column.find(
    (c: any) => c.meta.kz && (kz == null || (c.meta && c.meta.kz === kz))
  );
  if (!col) {
    return "";
  }
  const val = getDisplay(data, col);
  return val;
}
export function getKZValue(data: any, kz: string, column: any, getAic?: boolean, getKennung?: boolean) {
  if (!kz) {
    return "-!-";
  }

  const col = column.find(
    (c: any) => c.meta.kz && (kz == null || (c.meta && c.meta.kz === kz))
  );
  if (!col) {
    return "";
  }
  let aicUbergabe = false;
  if (getAic) {
    aicUbergabe = getAic;
  }
  const val = getValue(data, col, aicUbergabe, getKennung);
  return val;
}
export function getKZTitle(data: any, kz: string, column: any) {
  // um den Titel einer eigenschaft auszulesen
  if (!kz) {
    return "-!-";
  }

  const col = column.find(
    (c: any) => c.meta.kz && (kz == null || (c.meta && c.meta.kz === kz))
  );
  if (!col) {
    return "";
  }
  const val = col.title;
  return val;
}
export function getKennungTitle(kennung: string, column: any) {
  // um den Titel einer eigenschaft auszulesen
  if (!kennung) {
    return "-!-";
  }

  const col = column.find(
    (c: any) =>
      c.kennung &&
      (c.kennung === kennung || fixKennungForProperty(c.kennung) === kennung)
  );
  if (!col) {
    return "";
  }
  const val = col.title;
  return val;
}
export function getKennungValue(data: any, kennung: string, column: any) {
  if (!kennung) {
    return "-!-";
  }

  const col = column.find(
    (c: any) =>
      c.kennung && fixKennungForProperty(c.kennung) === kennung
  );
  if (!col) {
    return "";
  }
  const val = getValue(data, col);
  return val;

}
// export function getKennungKZValue(data: any, kennung: string, kz: string | null, column: any) {
//   if (!kennung) {
//     return "-!-";
//   }
//   const col = column.find(
//     (c: any) =>
//       c.kennung &&
//       fixKennungForProperty(c.kennung) === kennung &&
//       (kz == null || (c.meta && c.meta.kz === kz))
//   );
//   if (!col) {
//     return "";
//   }
//   return data[col.name];
// }
export function getKZColumn(kz: string, column: any[] = []) {
  if (!kz) {
    return null;
  }
  const col = column.find(
    (c: any) => c.meta.kz && (kz == null || (c.meta && c.meta.kz === kz))
  );
  return col;
}
export function getKZColumnsByPrefix(kz: string, columns: any[] = []) {
  if (!kz) {
    return null;
  }
  const cols = columns.filter(
    (c: any) => c.meta.kz && (kz == null || (c.meta && c.meta.kz.substr(0, kz.length) === kz))
  );
  return cols;
}
export function getNameColumn(name: string, column: any[] = []) {
  if (!name) {
    return null;
  }
  const col = column.find(
    (c: any) => c.name && (name == null || (c && c.name === name))
  );
  return col;
}
export function getColumnByKennung(kennung: string, kz: string | null = null, column: any[] = []) {
  if (!kennung) {
    return null;
  }
  const col = column.find(
    (c: any) =>
      fixKennungForProperty(c.kennung) === kennung &&
      (kz === null || c.meta.kz === kz)
  );
  return col;
}
export function findAmpelNumber(ampel: any) {
  const getAmpel: any = StaticGlobals.Current.globalsAmpel(
    Globals.Ampel,
    ampel
  );
  return getAmpel;
}
// Funktion um einen Sortierbaren Wert aus einem Wert zu machen.
export function getSortValue(row: any, sortColumn: any) {
  const value = row[sortColumn.name];
  if (sortColumn.datatype.indexOf("Datum") >= 0) {
    return value;
  }
  if (typeof value === "object") {
    if (value.bezeichnung !== undefined) {
      return value.bezeichnung;
    }
    if (value.faktor !== undefined && value.value !== undefined) {
      return value.value;
    }
  }
  return getDisplay(row, sortColumn);
}
export function fixTitle(text: string) {
  if (text.substr(0, 3).toLowerCase() === "web") {
    text = text.substr(4);
  }
  text = text.replace(/_/, " ");
  return text;
}
export function getDataCssClass(row: any, column: any) {
  const css: string =
    "qt" +
    column.type.substr(0, 1).toUpperCase() +
    column.type.substr(1) +
    " ";
  return css;
}
export function mapDataToKennung(data: any, ix: number | undefined) {
  if (!ix) {
    ix = 0;
  }
  // es kommt n array an also das erste
  console.log("keine Daten gefunden - erzeuge ein leeres");
  const kennData: any = {};
  for (let i = 0; i < data.columns.length; i++) {
    const col = data.columns[i];
    if (col.kennung) {
      const propertyname = fixKennungForProperty(col.kennung);
      if (data.data.length > ix && data.data[ix][col.name]) {
        kennData[propertyname] = data.data[ix][col.name];
      } else {
        kennData[propertyname] = null;
      }
    }
  }
  console.log(JSON.stringify(kennData));
  return kennData;
}
export function fixKennungForProperty(kennung: string) {
  if (!kennung) {
    console.warn("Cannot map property!!!");
    return "__Property__";
  }
  return kennung.replace(/\s/g, "_");
}
export function mapKennungToData(data: any, columns: any) {
  const mapped: any = {};
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i];
    if (col.kennung) {
      const propertyname: string = fixKennungForProperty(col.kennung);
      if (data[propertyname]) {
        mapped[col.name] = data[propertyname];
      } else {
        mapped[col.name] = null;
      }
    }
  }
  if (data.aic) {
    mapped.aic = data.aic; // wenn kein aic da dann mach ma einen
  }
  return mapped;
}

export function mapKennungToSaveData(data: any, columns: any) {
  const mapped: any = [];
  for (let i = 0; i < columns.length; i++) {
    const col = columns[i];
    if (col.kennung) {
      const propertyname = fixKennungForProperty(col.kennung);

      if (data[propertyname]) {
        let val = data[propertyname];
        if (typeof val === "object" && val.aic) {
          val = val.aic;
        } // nur aic schicken...
        mapped.push({ aic: col.aicEig, data: val, kennung: propertyname });
      }
    }
  }
  if (data.aic) {
    mapped.aic = data.aic;
  } // wenn kein aic da dann mach ma einen

  return mapped;
}
export function mapRowToSaveData(row: any, columns: any) {
  const mapped = [];
  for (const key of Object.keys(row)) {
    let val = row[key];
    const col = columns.find((ccol: any) => ccol.name === key);
    if (!col) {
      continue;
    }
    if (val == null || col.meta.keinSpeichern === true) {
      continue;
    }
    if (col.datatype === "Mass") {
      val = { aic: col.meta.einheit, value: val };
    } else if (typeof val === "object" && val.aic) {
      val = val.aic;
    } // nur aic schicken...

    mapped.push({ aic: col.aicEig, data: val });
  }
  // if (row.aic) mapped.aic = row.aic; // wenn kein aic da dann mach ma einen
  // if (row.aic_Bew_pool) mapped.aic = row.aic_Bew_pool; // wenn kein aic da dann mach ma einen

  return mapped;
}

export function decimalColorToHTMLcolor(colorNum: number) {
  // tslint:disable-next-line: no-bitwise
  const x = (colorNum & 0xffffff).toString(16);
  console.log(x);
}

export function getGroupSumValue(rows: any[], column: any) {
  if (!column.meta.ergebnisArt) {
    return null;
  }

  let sum = 0;
  let factor = -1;

  rows.forEach(row => {
    let val = row[column.name];
    if (val === undefined || val === null) {
      return;
    }

    if (typeof val === "object" && val.faktor !== undefined) {
      factor = val.faktor;
      val = val.value;
    }
    if (column.meta.ergebnisArt === "sum") {
      sum += val;
    } else if (column.meta.ergebnisArt === "last") {
      sum = val;
    } else if (column.meta.ergebnisArt === "min") {
      sum = Math.min(val);
    } else if (column.meta.ergebnisArt === "maxLength") {
      sum = Math.max(val);
    }
  });

  if (factor > 0) {
    return { faktor: factor, value: sum };
  }

  return sum;
}
// Bsp zum Sortieren mit getDisplay
// for (let i = 0; i < this.data.length; i++) {
//     const row = this.data[i];
//     const c = getNameColumn(sortBez, this.columns);
//     sortedlist = this.data.sort((a: any, b: any) => {
//       let f1 = "0";
//       if (a) {
//         f1 = getDisplay(a, c);
//       }
//       let f2 = "0";
//       if (b) {
//         f2 = getDisplay(b, c);
//       }
//       if (!f1) {
//         f1 = "";
//       }
//       if (!f2) {
//         f2 = "";
//       }
//       if (f1 > f2) {
//         return 1;
//       } else {
//         return -1;
//       }
//     });
//     return sortedlist;
//   }

export function toNumber(x: any) {
  return Number.parseFloat(('' + x).trim());
}

export function roundNumber(num: number, digits: number) {
  const exp = 10 * digits;
  return Math.round(num * exp) / exp;
}


export function numberToStringWithThousends(
  valueToFormat: number,
  fractionDigits: number = 2,
  decimalSep: string = ",",
  thousands: string = ".",
  culture: string = "de-AT") {
  const x = new Intl.NumberFormat(culture,
    { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits })
    .formatToParts(valueToFormat).map(({ type, value }) => {
      switch (type) {
        case 'group': return thousands;
        case 'decimal': return decimalSep;
        default: return value;
      }
    }).reduce((txt: string, part: string) => txt + part);
  if (x === 'NaN') { debugger; }
  return x;
}
