
























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import { setAppSnackbarInstance } from "@/UIHelper";
@Component({
  components: {
    "css-style": {
      render(createElement: any): any {
        return createElement("style", this.$slots.default);
      }
    }
  }
})
export default class AppSnackbars extends Vue {
  private snackbars: SnackbarData[] = [];
  private distance = 10;
  private defaultHeight = 50;
  private messageHeight = 80;
  private margins: { [id: string]: number } = { top: 80, bottom: 20 };

  public mounted() {
    // console.log("mounted...");
    setAppSnackbarInstance(this, this.pushSnackbar);
  }
  private getPosition(bar: SnackbarData, ix: number) {
    const margin = this.margins[bar.position ?? "bottom"];
    let b = margin;
    if (!Constants.BottomMenuVisible) {
      b = 10;
    }
    for (let i = 0; i < ix; i++) {
      const obar = this.snackbars[i];
      if (
        bar.position !== obar.position ||
        bar.hPosition !== obar.hPosition ||
        bar === obar ||
        !obar.show
      ) {
        continue;
      }
      const height =
        obar.height ??
        (bar.isMessage ? this.messageHeight : this.defaultHeight);
      b += height + this.distance;
    }

    const maxHeight = window.innerHeight - margin - 180;
    if (b > maxHeight) {
      b = (b % maxHeight) + margin;
    }
    return b;
  }

  public pushSnackbar(data: SnackbarData) {
    data.height = data.height ?? 100;
    data.show = true;
    data.style = data.style ?? "rounded";
    data.position = data.position ?? "bottom";
    data.hPosition = data.hPosition ?? "center";
    data.css = data.css ?? this.getDefaultCSS();
    const text = data.text;
    const doppelt = this.snackbars.find(sn => sn.text === text);
    if (doppelt) {
      return;
    }
    this.snackbars.push(data);
    if (data.duration && data.duration > 0) {
      setTimeout(() => this.closeSnackbar(data), data.duration * 1);
    }
  }
  private getDefaultCSS() {
    return Constants.isMobile
      ? "border-radius:.5em;padding: 8px; margin-bottom:50px;"
      : "border-radius:1em; padding:1em 0.5em;margin-bottom:1.5em;";
  }

  public closeSnackbar(bar: SnackbarData, manual: boolean = false) {
    bar.closedManual = manual;
    this.barClosed(bar);
  }

  private barClosed(bar: SnackbarData) {
    bar.show = false;
    this.snackbars = this.snackbars.filter(sb => sb !== bar && sb.show);
    if (bar.onClose) {
      bar.onClose(bar);
    }
  }
}
