

















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { showDialog } from "@/UIHelper";
import { calcFunctionNew } from "@/CalcHelperStatic";
import CreateList from "./CreateList";
import { Globals } from "@/Globals";
// import BaseEditor from "./BaseEditor.vue";
import { refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class SpeediDial extends CreateList {
  protected refreshItems(): void {
    throw new Error("Method not implemented.");
  }
  @Prop() protected modell!: string;
  @Prop() protected modell1!: string;
  // @Prop() public aic!: number;
  @Prop() protected pdf!: string;
  @Prop({ default: "left" }) protected direction!: string;
  @Prop({ default: false }) protected editDialog!: boolean;
  @Prop({ default: false }) protected setEdit!: boolean;
  @Prop({ default: false }) protected print!: boolean;
  protected editZeitbereich: APIZeitbereich | null = null;
  @Prop() protected printItems!: PrintItems[];
  private edit: boolean = false;
  private showDialog: boolean = false;
  private hover: boolean = false;
  private fab: boolean = false;
  private transition: string = "scale-transition";
  private showPrint: boolean = false;
  private tooltip: string = "";
  private tooltip1: string = "";
  @Prop({ default: false }) private pers!: boolean;
  @Prop({ default: true }) protected backOnSave!: boolean;
  public mounted() {
    if (this.modell) {
      const begriffModell = this.$globalsKennung(Globals.Begriff, this.modell);
      if (begriffModell) {
        this.tooltip = begriffModell.tooltip;
      }
    }
    if (this.modell1) {
      const begriffModell = this.$globalsKennung(Globals.Begriff, this.modell1);
      if (begriffModell) {
        this.tooltip1 = begriffModell.tooltip;
      }
    }
  }
  protected backOrReload() {
    if (this.backOnSave) {
      this.$emit("back", true);
    } else {
      this.reloadData();
    }
  }
  private onCalcFunction(modell: string, aic: number) {
    const me = this;
    if (modell) {
      calcFunctionNew(modell, aic, false, me, this.editZeitbereich, []);
      setTimeout(() => refreshFullPageContent());
    }
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    const aic = this.aic;
    const pers = this.pers;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: { zeitbereich: this.$api.zeitbereich, printItem, aic, pers },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      }
    });
  }
}
