





























































































































import { Component, Prop, Watch } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
export declare interface TreeItem {
  bezeichnung: string;
  aic: number;
  stt: string;
  sttBez: string;
  children: TreeItemChild[];
}
declare interface TreeItemChild {
  aic: number;
}

@Component
export default class EditHierarchie extends EditComponent {
  @Prop({ default: "BEZEICHNUNG" }) public textKennung!: string;
  @Prop({ default: false }) public fireOnText!: boolean;
  @Prop() public sttVon!: number;
  @Prop() public sttBis!: number;
  @Prop() public selectedItem!: number;
  @Prop() public aicRolle!: number;
  protected items: TreeItem[] = [];
  private fields: any = {
    dataSource: this.items,
    value: "aic",
    text: "bezeichnung",
    child: "children"
  };
  @Prop({ default: false }) public clearable!: boolean;
  @Prop({ default: false }) public noSearch!: boolean;
  private showList: boolean = false;

  protected zeitbereich!: APIZeitbereich | null;
  private list: any[] = [];
  private nullElement: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private showLoading: boolean = false;
  private selected: any[] = [];
  private hintText: string = "";
  private selectedElement: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    ),
    stt: "",
    sttBez: ""
  };
  private field: any = this.queryColumn;
  private errorMessages: string[] = [];
  private showSettings: boolean = false;
  private sysAbfrage: any = null;
  private selectedText: string = "";
  private height: string = "400px";
  private menuwith: string = "100%";
  private initialized: boolean = false;

  // private fields: any = { text: "bezeichnung", value: "aic" };
  private search: string = "";

  public mounted() {
    try {
      this.mountedBase();
      this.showLoading = true;
      if (this.queryZeitbereich) {
        this.zeitbereich = this.queryZeitbereich;
      }
      const me = this;
      if (this.sttVon && this.sttBis) {
        const aicSttVon = this.sttVon;
        const aicSttBis = this.sttBis;
        const aicRolle = this.aicRolle;
        this.$api.getStammtyp(
          (data: any) => me.dataLoadedHierarchie(data),
          aicSttVon,
          null,
          false,
          aicRolle
        );
        this.initialized = true;
      } else if (this.field && this.field.type === "hierarchie") {
        const aicEig = this.field.aicEig;
        const aicSttVon = this.field.meta.Stt;
        const aicSttBis = this.stammtyp;
        const aicRolle = this.aicRolle;
        this.$api.getHierarchie(
          (data: any) => me.dataLoadedHierarchie(data),
          aicEig,
          aicSttVon,
          aicSttBis,
          aicRolle,
          null,
          false
        );
        this.data = this.getInitValue();
        const element = this.data;
        this.list.push(element);
        this.initialized = true;
        this.selected = [];
        this.selected.push(element.aic);
        this.hintText = element.bezeichnung + ", " + element.sttBez;
        this.selectedText = element.bezeichnung;
        this.selectedElement = element;
      } else {
        let name = "" + this.kennung;
        if (this.field) {
          name += " " + this.field.bezeichnung;
        }
        console.log("EditHierarchie: no data and config for " + name);
        this.initialized = true;
      }
    } catch (err) {
      console.log(err);
    }
  }

  private filterFunction(
    item: any,
    queryText: string,
    itemText: string
  ): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (
        itemText &&
        itemText.toLowerCase &&
        itemText.toLowerCase() + ""
      ).startsWith(queryText)
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) =>
          t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }

  private dataLoadedHierarchie(data: any) {
    try {
      const valueList = data.data;
      if (this.sttVon) {
        this.list = data.data;
      } else {
        this.items = data.data;
      }
      this.$emit("loaded", this.items);
      if (!this.selectedText) {
        this.selectedText = "bitte auswählen";
      }
      if (this.selectedItem) {
        this.selectedValueChanged(this.selectedItem);
      }
      this.initialized = true;
      this.showLoading = false;
      return;
    } catch (err) {
      console.log(err);
      this.initialized = true;
      this.showLoading = false;
    }
    this.$emit("loaded", this.items);
    if (!this.selectedText) {
      this.selectedText = "bitte auswählen";
    }
  }

  protected selectedValueChanged(data: any) {
    const isMobile = Capacitor.getPlatform() !== "web";
    if (isMobile) {
      Keyboard.hide();
    }
    let comboVal: any;
    if (this.sttVon) {
      comboVal = data;
    } else {
      comboVal = data[0];
    }
    if (this.sttVon && !this.list) {
      return;
    } else if (!this.sttVon && (!this.items || this.items.length === 0)) {
      return;
    }
    if (comboVal) {
      let findSelect;
      if (this.sttVon) {
        findSelect = this.list.find(x => x.aic === comboVal);
      } else {
        findSelect = this.items.find(x => x.aic === comboVal);
      }
      if (findSelect) {
        this.selected = [];
        this.selected.push(findSelect.aic);
        this.selectedText = findSelect.bezeichnung;
        this.selectedElement = findSelect;
        this.list.push(findSelect);
      }
    }
    const val = this.selected;
    if (
      val == null ||
      (val && val && (this.fireOnText || typeof val !== "string"))
    ) {
      this.$emit("selected", this.selected);
      this.valueChanged(val); // zum event triggern
      this.showList = false;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }

  public validate() {
    try {
      if (!this.enabled) {
        this.errorText = "";
        return true;
      } // disabled Controls sind IMMER valide ;)
      if (!this.initialized) {
        // Liste leer also nicht initialisiert...
        return false; // soll eine noch nicht initialisierte Combobox valide sein ?
      }

      if (this.required) {
        if (this.selected && this.selected) {
          this.errorText = "";
          return true;
        }
        this.errorText = this.requiredMessage;
        return false;
      }
      this.errorText = "";
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  protected getInitValue(): any {
    if (this.column && this.column.meta) {
      if (
        this.column.meta.neuArt === "Limit" ||
        this.column.meta.neuArt === "Last"
      ) {
        return this.getInitValueBase();
      }
    }
    return this.getInitValueBase();
  }
  protected openSettings() {
    const siteName = this.column.titel;
    this.$root.$emit("showSite", {
      site: "EditStamm",
      data: { query: this.sysAbfrage, aic: [this.selected] },
      title: this.column.title
    });
  }
}
