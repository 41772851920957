import { Globals, StaticGlobals } from "@/Globals";
export default class PasswordValidator {
  public static countDigits(password: string): number {
    return password.replace(/[^0-9]/g, "").length;
  }
  public static countUpperCase(password: string): number {
    return password.replace(/[a-z0-9]/g, "").length;
  }
  public static countSpecial(password: string): number {
    return password.replace(/[^\!\[\]\{\}\$\%\&\/\\\(\)\?\=\#\<\>\+\*\@]/g, "").length;
  }
  public static countWrongSpecial(password: string): number {
    return password.replace(/[\w|_^\!\[\]\{\}\$\%\&\/\\\(\)\?\=\#\<\>\+\*\@]/g, "").length;
  }

  public static isValidNameChar(c: string) {
    if (";\",'".indexOf(c) >= 0) { return false; }
    return true;
  }
  private static getText(kennung: string, fallback: string) {
    if (StaticGlobals.Current) {
      // in den UnitTests nicht gesetzt und muss daher fallback verwenden...
      const x = StaticGlobals.Current.globalsKennung(
        Globals.Begriff,
        kennung,
        false
      );
      if (x?.memo) {
        return x.memo;
      }
      if (!fallback) {
        return x.bezeichnung;
      }
      return fallback;
    }
    return fallback;
  }

  public static validate(
    password: string,
    minLength: number,
    minDigit: number,
    minSpecialCharacters: number,
    minUpperCase: number
  ): PasswordValidationResult {
    if (password.length < minLength) {
      return {
        isValid: false,
        message: this.getText("Web_PWKurz", "Passwort ist zu kurz!")
      };
    }
    if (PasswordValidator.countDigits(password) < minDigit) {
      return {
        isValid: false,
        message: this.getText("Web_PW_Ziffern", "Passwort hat zu wenig Ziffern!")
      };
    }
    if (PasswordValidator.countSpecial(password) < minSpecialCharacters) {
      return {
        isValid: false,
        message: this.getText(
          "Web_PWSonderzeichen",
          "Passwort hat zu wenig Sonderzeichen!"
        )
      };
    }
    if (PasswordValidator.countUpperCase(password) < minUpperCase) {
      return {
        isValid: false,
        message: this.getText("Web_PWGrossbuchstaben", "Passwort hat zu wenig Großbuchstaben!")
      };
    }
    if (PasswordValidator.countWrongSpecial(password) > 0) {
      return {
        isValid: false,
        message: "Passwort enthält nicht erlaubte Sonderzeichen!"
      };
    }
    return {
      isValid: true,
      message: this.getText("Web_PasswortGeandert", "Passwort wurde geprüft und erfolgreich geändert")
    };
  }
}

export interface PasswordValidationResult {
  isValid: boolean;
  message: string;
}
