var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.getStyle())},[(
      !_vm.showButtons &&
        (_vm.drucke.length > 0 || _vm.modelle.length > 0 || _vm.frame.length > 0)
    )?_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":"","color":"grey"}},[_vm._v("mdi-dots-vertical")])],1)],1)]}}],null,false,1787599877)},[_c('v-list',[(_vm.drucke.length > 0)?_c('v-list-group',{attrs:{"prepend-icon":"mdi-printer"}},_vm._l((_vm.drucke),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.openDruckVorschau(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(item.bezeichnung)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])],1)}),1):_vm._e(),(_vm.modelle && _vm.modelle.length > 0)?_c('v-list-group',{attrs:{"title":"Modelle","prepend-icon":"play_circle_outline"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Modelle")])]},proxy:true}],null,false,466654081)},_vm._l((_vm.modelle),function(modellItem,index){return _c('v-list-item',{key:index},[_c('CalcButton',{attrs:{"text":modellItem.bezeichnung,"modell":modellItem.kennung,"icon":modellItem.icon,"dark":false,"color":modellItem.farbe,"plain":true,"vecStamm":_vm.vector},on:{"calculated":_vm.onCalculated}})],1)}),1):_vm._e(),(_vm.frame && _vm.frame.length > 0)?_c('v-list',_vm._l((_vm.frame),function(item,index){return _c('v-list-item',{key:'p' + index},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openFrame(item)}}},[_c('v-icon',[_vm._v("open_in_new")]),_vm._v(" "+_vm._s(item.bezeichnung))],1)],1)}),1):_vm._e()],1)],1)],1):_vm._e(),(_vm.showButtons)?_c('div',[(_vm.drucke && _vm.drucke.length > 0)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":_vm.dark,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}],null,false,3084048995)},[_c('v-list',_vm._l((_vm.drucke),function(item,index){return _c('v-list-item',{key:'p' + index,on:{"click":function($event){return _vm.openDruckVorschau(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(item.bezeichnung)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltip))])])],1)}),1)],1):_vm._e(),(_vm.modelle && _vm.modelle.length > 0)?_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"80"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":_vm.dark,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("play_circle_outline")])],1)]}}],null,false,3368749758)},[_c('v-list',_vm._l((_vm.modelle),function(modellItem,index){return _c('v-list-item',{key:'m' + index},[_c('CalcButton',{attrs:{"text":modellItem.bezeichnung,"modell":modellItem.kennung,"icon":modellItem.icon,"dark":false,"color":modellItem.farbe,"plain":true,"vecStamm":_vm.vector,"useVecStamm":_vm.onlyVector},on:{"calculated":_vm.onCalculated}})],1)}),1)],1):_vm._e(),(_vm.frame && _vm.frame.length > 0)?_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":_vm.dark,"text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("open_in_new")])],1)]}}],null,false,4243676679)},[_c('v-list',_vm._l((_vm.frame),function(item,index){return _c('v-list-item',{key:'p' + index},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openFrame(item)}}},[_c('v-icon',[_vm._v("open_in_new")]),_vm._v(" "+_vm._s(item.bezeichnung))],1)],1)}),1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }