import L, { Map, Icon, TileLayer, LayerGroup } from "leaflet";

const InitialZoomLevel = 10;

export interface MapMarker {
    lat: number;
    long: number;
    text?: string;
    icon?: string | Icon;
    closed?: boolean;
}

export interface MapPolyLine {
    points: Array<[number, number]>;
    color: string;
    weight: number;
    opacity: number;
    text: string;
    closed?: boolean;
}

export interface MapDefinition {
    marker: MapMarker[] | undefined;
    lines: MapPolyLine[] | undefined;
}

let divContainer: HTMLDivElement | undefined;
let myMap: Map | undefined;
let tiles: TileLayer | undefined;
let layergroup: LayerGroup | undefined;
let initialized: boolean = false;

const logoIcon = L.icon({
    iconUrl: "map_marker.png",
    iconSize: [41, 41],
    iconAnchor: [21, 41]
});

export function checkAndInitializeMap(lat: number, long: number, container: HTMLDivElement, def: MapDefinition
) {
    if (initialized) { return; }

    if ((container as any).$el) { container = (container as any).$el; }

    if (!divContainer) {
        divContainer = document.createElement("div") as HTMLDivElement;
        divContainer.style.width = "100%";
        divContainer.style.height = "400px";
    } else {
        if (divContainer?.parentElement !== container) { unbindMapFromSite(); }
    }
    if (divContainer?.parentElement !== container) {
        container.appendChild(divContainer);
    }

    if (!myMap) {
        myMap = L.map(divContainer, {

        }).setView(
            [lat, long],
            InitialZoomLevel
        );
    } else {
        myMap.setView([lat, long], InitialZoomLevel);
    }

    if (!tiles) {
        tiles = L.tileLayer(
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            {
                // tileSize: 512,
                // zoomOffset: -1,
                attribution:
                    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                maxZoom: 15,
                minZoom: 5,
                // errorTileUrl: "/path/to/default/tile.png"
            }
        ).addTo(myMap);
    }

    layergroup = L.layerGroup()
        .addTo(myMap);

    if (def?.marker) {
        const icon = logoIcon;

        def.marker.forEach((m: MapMarker) => {
            // debugger;
            const marker = L.marker([m.lat, m.long], { icon });
            if (m.text) { marker.bindPopup(m.text); }
            if (!m.closed) { marker.openPopup(); }
            marker.addTo(layergroup!);
        });
    }

    if (def?.lines) {
        def.lines.forEach((l: MapPolyLine) => {
            L.polyline(
                l.points,
                { color: l.color, weight: l.weight, opacity: l.opacity }
            )
                .bindPopup(l.text)
                .openPopup()
                .addTo(layergroup!)
                .on("mouseover", (e: any) => {
                    e.sourceTarget.setStyle({ color: "red" });
                })
                .on("mouseout", (e: any) => {
                    e.sourceTarget.setStyle({ color: "green" });
                });
        });
    }
    initialized = true;
    setTimeout(() => myMap!.invalidateSize(), 100);
}

export function fitMapBounds(bounds: [[number, number], [number, number]]) {
    myMap?.fitBounds(bounds);
}
export function flyMapTo(pos: [number, number], zoom: number = 9) {
    myMap?.flyTo(pos, zoom);
}

export function unbindMapFromSite() {
    // debugger;
    if (layergroup) {
        myMap?.removeLayer(layergroup);
        layergroup = undefined;
    }
    // myMap?.remove();
    divContainer?.parentElement?.removeChild(divContainer);
    // myMap = undefined;
    // tiles = undefined;
    initialized = false;
}
