


















































































































































































































































































































































































































































































import BaseEditor from "@/components/BaseEditor.vue";
import EditPage from "@/components/EditPage";
// import { Base } from "@syncfusion/ej2-inplace-editor";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { getKZValueDisplay } from "@/DataHelper";
import BaseVGrid from "../../components/BaseVGrid.vue";

@Component({
  provide: {}
})
export default class StammPerson extends EditPage {
  constructor() {
    super();
    this.query = "Web_Stammdaten_MA";
    this.bewegungsdaten = false;
    this.showLoading = true;
  }
  @Prop({ default: null }) protected onClosed!: () => void | null;
  private wasopen = false;
  private drawer: boolean = true;
  private mini: boolean = true;

  private showBackdrop: boolean = true;
  private content0 = "...";
  private dialogOpen: boolean = false;
  private showDialog: boolean = false;
  private step: number = 0;
  private myHight: number = window.innerHeight;
  // private avatarIcon: any;
  private fab: boolean = false;
  private initalized: boolean = false;
  private stammBezeichnung: string = "";
  private stammKennung: string = "";
  private selected: number = 0;
  private dialog: any = {
    aic: 0,
    title: "--!!--",
    componentName: "None",
    zeitbereich: null,
    showCustomEditor: false,
    showCustomGrid: false,
    query: "Web_NeuanlageStammMARK",
    lastValues: null,
    newData: false,

    width: 600,
    height: 200,
    fullscreen: false
  };
  private items: any[] = [];
  private avatar: string = "";
  private sttVon: number = 0;
  private sttBis: number = 0;
  private aicRolle: number = 0;
  private showData: boolean = false;
  private edit: boolean = false;
  private showBenutzer: boolean = false;
  public mounted() {
    if (this.siteData && this.siteData?.aic !== 0) {
      this.selected = this.siteData.aic;
      this.siteData.aic = 0;
    }

    this.items.push({
      text: "Allgemein",
      icon: "supervised_user_circle",
      color: "primary",
      step: 1
    });
    if (this.checkBegriff("Web_StammdatenMA_ReisekostenParameter")) {
      this.items.push({
        text: "Reisekosten",
        icon: "flight",
        color: "success",
        step: 2
      });
    }
    this.items.push({
      text: "Adresse",
      icon: "home",
      color: "success",
      step: 3
    });
    this.items.push({
      text: "Bank",
      icon: "savings",
      color: "primary",
      step: 4
    });
    if (this.checkBegriff("Web_StammMA_PZE_Parameter")) {
      this.items.push({
        text: "Zeiterfassung",
        icon: "schedule",
        color: "warning",
        step: 5,
        subsites: [
          {
            text: "vor. Austritt",
            icon: "free_cancellation",
            color: "warning",
            step: 6
          }
        ]
      });
    }
  }
  public initialize() {
    this.sttVon = this.header.aicStt;
    this.sttBis = this.header.aicStt;
    this.aicRolle = this.header.aicRolle;
    const avatarData = getKZValueDisplay(this.data, "avatar", this.columns);
    if (avatarData) {
      if (avatarData.data) {
        this.avatar = avatarData.data;
      } else {
        this.loadImage(avatarData);
        this.avatar = this.avatarIcon;
      }
    }
    this.stammBezeichnung = this.getKennungValueDisplay(
      this.data,
      "VORNAME",
      this.columns
    );
    this.stammBezeichnung =
      this.stammBezeichnung +
      " " +
      this.getKennungValueDisplay(this.data, "ZUNAME", this.columns);
    this.stammKennung = this.getKennungValueDisplay(
      this.data,
      "E-MAIL",
      this.columns
    );
    if (this.stammKennung === "" || !this.stammKennung) {
      this.stammKennung = this.stammBezeichnung.replace(/\s+/g, "");
    }
    this.initalized = true;
  }
  // private loadImage(avatar: any) {
  //   if (!avatar.data) {
  //     this.avatarIcon = this.$api.fullUrl(
  //       "imageM/" + this.$api.user.id + ":" + +avatar.aic + "_" + avatar.name
  //     );
  //   }
  // }
  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  protected backdrop() {
    const backdropBtn = this.$refs.backdropBtn as any;
    if (backdropBtn.$el.classList.contains("e-active")) {
      backdropBtn.$el.textContent = "True";
      // enable the backdrop property
      this.showBackdrop = false;
    } else {
      backdropBtn.textContent = "False";
      // disable the backdrop property
      this.showBackdrop = true;
    }
  }
  private newItem(item: any) {
    this.dialog.aic = undefined;
    this.dialog.newData = true;
    this.dialogOpen = true;
  }
  private editItem(edit: any) {
    this.edit = edit;
  }
  private onBack() {
    this.dialogOpen = false;
  }
  private onDialogClose() {
    this.reloadData();
    this.dialogOpen = false;
  }
  private changedMe(val: boolean) {
    // wenn grad geschlossen app informieren
    if (this.drawer) {
      this.wasopen = true;
    } else if (!this.drawer && this.onClosed && this.wasopen) {
      this.onClosed();
      this.wasopen = false;
    }
  }
  private valueChanged(x: any) {
    this.edit = false;
    this.showData = true;
    this.queryAIC = x[0];
    this.selected = x[0];
    if (this.siteData && this.siteData?.aic !== 0) {
      this.siteData.aic = 0;
    }
    this.mountedBase();
  }
}
