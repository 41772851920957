

















































































































































import { showDialog, showSnackbar } from "@/UIHelper";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import {
  AufgabeStatusZuordnung,
  AufgabeStatusDruck,
  AufgabeStatusModell,
  AufgabeStatusFrame
} from "../ChartTypesAndFunctions";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";

@Component
export default class ZuordnungDashboardMenu extends Vue {
  @Prop({ default: false }) public showButtons!: boolean;
  @Prop({ default: false }) public onlyVector!: boolean;
  @Prop({ default: false }) public persoenlich!: boolean;
  @Prop() public zuordnungen!: AufgabeStatusZuordnung[];
  @Prop() public aic!: number;
  @Prop({ default: "white" }) public color!: string;
  @Prop({ default: -30 }) public right!: number;
  @Prop({ default: 0 }) public top!: number;
  @Prop() public vector?: number[];
  @Prop({ default: false }) public dark!: boolean;
  private drucke: AufgabeStatusDruck[] = [];
  private modelle: AufgabeStatusModell[] = [];
  private frame: AufgabeStatusFrame[] = [];

  public mounted() {
    this.drucke = this.zuordnungen
      ?.filter(z => z.gruppe === "Druck")
      .map(z => z as AufgabeStatusDruck);
    // this.drucke = this.drucke.sort(dynamicSort("bezeichnung");
    this.modelle = this.zuordnungen
      ?.filter(z => z.gruppe === "Modell")
      .map(z => z as AufgabeStatusModell);
    this.frame = this.zuordnungen
      ?.filter(z => z.gruppe === "Frame")
      .map(z => z as AufgabeStatusFrame);
  }

  private getStyle() {
    if (this.aic) {
      return {
        // height: 0,
        // position: "absolute",
        // "margin-top": this.top + "px",
        // "margin-right": this.right + "px"
      };
    }
    return {
      padding: 0
      // height: "40px"
      // display: "inline-block",
      // width: "60%",
    };
  }

  private openDruckVorschau(item: AufgabeStatusDruck) {
    const me = this;
    const printItem = item;
    let aic = this.$api.user.aic;
    // aic kann von den Aufträgen übergeben werden (CChart)
    if (this.aic) {
      aic = this.aic;
    }
    // const aic = this.aic;
    const vecStamm = this.vector ?? []; // this.vecStamm;
    if (this.onlyVector && vecStamm.length === 0 && !this.aic) {
      showSnackbar({
        text: "Bitte wählen Sie zuerst einen Datensatz aus!",
        color: "warning"
      });
      // console.log("keine Satz markiert");
      return;
    }
    const pers = this.persoenlich;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: { zeitbereich: this.$api.zeitbereich, printItem, aic, vecStamm, pers },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      }
    });
  }
  private openFrame(item: any) {
    const me = this;
    const aic = this.aic;
    const site = item.alias ?? item.kennung;
    onShowSite({
      title: item.name,
      site,
      data: {
        zeitbereich: this.$api.zeitbereich,
        item,
        aic,
        showBack: true,
        toggleUebergabe: item.toggleSight, // hab noch keine Definition... fehlt noch
        name: item.name,
        titel: item.name,
        gotoToday: false
      },

      callOnLeave: () => {
        setTimeout(() => refreshFullPageContent());
      }
    });
  }
  private onCalculated(args: any) {
    // nach der Berechnung alles neu laden!
    setTimeout(() => refreshFullPageContent());
  }
}
