



























































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { showSnackbar } from "@/UIHelper";
import { refreshFullPageContent } from "@/NavigationHelper";
import { reloadApp } from "@/NativeUpdateProvider";
@Component
export default class PersEinstellungen extends Vue {
  private zeitFormat: boolean = false;
  private showLoading: boolean = false;
  private infoData: any;
  private data: any;
  private sprache: Sprache[] = [];
  private land: Land[] = [];
  private mandant: Mandant[] = [];
  private showSave: boolean = false;
  private selectedMandant: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedSprache: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedLand: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };

  public mounted() {
    const me = this;
    me.showLoading = true;
    // me.$api.getBenutzergruppen();
    me.$api.getSprachen().then((x: any) => {
      me.sprache = x.data.data;
      me.$api.getPersEinst().then((res: any) => me.dataLoaded(res));
    });
    // me.$api.getLaender().then((resData: any) => {
    //   me.land = resData.data;
    //   me.$api.getSprachen().then((x: any) => {
    //     me.sprache = x.data;
    //     me.$api.getPersEinst().then((res: any) => me.dataLoaded(res));
    //   });
    // });
  }
  private dataLoaded(data: any) {
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }
    this.data = data.data;
    if (this.data.zeitFormat === "min") {
      this.zeitFormat = true;
    }
    if (this.data.mandant) {
      for (let i = 0; i < this.$api.user.mandantW.length; i++) {
        const mid = this.$api.user.mandantW[i];
        const mandant: any = this.$api.Mandanten.find(
          (m: any) => m.aic === mid
        );
        if (mandant) {
          if (mid === this.data.mandant) {
            this.selectedMandant = mid;
            // this.selectedMandant.bezeichnung = mandant.bezeichnung;
            // this.selectedMandant.aic = mandant.aic;
          }
          this.mandant.push({
            text: mandant.bezeichnung,
            data: mandant
          });
        }
      }
    }
    // if (this.land && this.land.length > 0) {
    //   for (let i = 0; i < this.land.length; i++) {
    //     const landAic = this.data.land;
    //     const land: any = this.land.find((m: any) => m.aic === landAic);
    //     if (land) {
    //       if (landAic === this.data.land) {
    //         this.selectedLand = land;
    //       }
    //     }
    //   }
    // }
    if (this.data.sprache && this.sprache && this.sprache.length > 0) {
      for (let i = 0; i < this.sprache.length; i++) {
        const meineSprache = this.data.sprache;
        const sprache: any = this.sprache.find(
          (m: any) => m.aic === meineSprache
        );
        if (sprache) {
          if (meineSprache === this.data.sprache) {
            this.selectedSprache = sprache;
          }
        }
      }
    }
    this.showLoading = false;
  }
  private setPersEinstellung() {
    let format = "auto";
    if (this.zeitFormat) {
      format = "min";
      this.$api.user.zeitFormat = "min";
    } else {
      this.$api.user.zeitFormat = "auto";
    }
    this.$api
      .postPersEinst(
        this.selectedSprache.aic,
        this.selectedLand.aic,
        this.data.mandant,
        format
      )
      .then((res: any) => {
        const changed = res.data.changed;
        const info = res.data.error;
        if (info) {
          showSnackbar({
            text: info,
            color: "error"
          });
        } else if (changed) {
          this.$api.getGlobalBegriffe();
          this.$api.getGlobalHoliday();
          // refreshFullPageContent();
          reloadApp();
          showSnackbar({
            text: "Daten wurden erfolgreich geändert",
            color: "info"
          });
          this.showSave = false;
        }
      });
  }
  protected selectedMandantChanged(args: any) {
    const mandant: any = this.mandant.find((m: any) => m.text === args);
    this.selectedMandant.aic = mandant.data.aic;
    this.showSave = true;
  }
  protected selectedLandChanged(args: any) {
    const land: any = this.land.find((m: any) => m.bezeichnung === args);
    this.selectedLand = land;
    this.showSave = true;
  }
  protected selectedSpracheChanged(args: any) {
    const sprache: any = this.sprache.find((m: any) => m.bezeichnung === args);
    this.selectedSprache = sprache;
    this.showSave = true;
  }
  private changeFormat() {
    this.showSave = true;
  }
}
declare interface Land {
  aic: string;
  bezeichnung: string;
  iso3166?: string;
  kennung?: string;
  standard?: boolean;
}
declare interface Sprache {
  aic: string;
  anzahl: string;
  bezeichnung: string;
  iso639?: string;
  kennung?: string;
}
declare interface Mandant {
  data: string;
  text: string;
}
