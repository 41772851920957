







































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Capacitor } from "@capacitor/core";
import { getDeviceInfo, getDeviceType } from "@/DeviceInfoPlugin";
import { getGeoLocation } from "@/GeoHelper";
@Component
export default class AllWelcome extends Vue {
  private async mounted() {
    this.platform = Capacitor.getPlatform();
    const me = this;
    getDeviceInfo().then(t => {
      if (this.$isTablet()) {
        this.platform += " tablet " + getDeviceType();
      } else if (this.$isPhone()) {
        this.platform += " phone " + getDeviceType();
      }
    });
    const position = await getGeoLocation();
    const latitute = position.latitude;
    const longitude = position.longitude;
    this.location = latitute + " " + longitude;
  }
  private dialog2 = false;
  private dialog = false;
  private dialog3 = false;
  private aic = 0;
  private count = 0;
  private platform: string = "xx";
  private location: string = "xx";
  /*  private data() {
    const platform = Capacitor.getPlatform();
    const me = this;
    const data = {
      username: "",
      dialog: false,
      dialog2: false,
      dialog3: false,
      aic: 0,
      count: 0,
      platform,
    };
    return data;
  }*/
}
