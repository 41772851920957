import { render, staticRenderFns } from "./Monatsabschluss.vue?vue&type=template&id=36a37816&scoped=true&"
import script from "./Monatsabschluss.vue?vue&type=script&lang=ts&"
export * from "./Monatsabschluss.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a37816",
  null
  
)

export default component.exports