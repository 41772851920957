var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-speed-dial',{staticClass:"pr-5",attrs:{"fab":"","direction":"left","open-on-hover":_vm.hover,"transition":"slide-y-reverse","top":true,"right":true,"bottom":false,"left":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"text":"","icon":"","small":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" more_vert ")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.modell && _vm.aic)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"success"},on:{"click":function($event){return _vm.onCalcFunction(_vm.modell, _vm.aic)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("done")])],1):_vm._e()]}}])},[(_vm.tooltip)?_c('span',[_vm._v(" "+_vm._s(_vm.tooltip))]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.modell1 && _vm.aic)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"error"},on:{"click":function($event){return _vm.onCalcFunction(_vm.modell1, _vm.aic)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("clear")])],1):_vm._e()]}}])},[(_vm.tooltip1)?_c('span',[_vm._v(" "+_vm._s(_vm.tooltip1))]):_vm._e()]),(_vm.setEdit && !_vm.edit)?_c('v-btn',{attrs:{"small":"","relative":"","text":"","icon":""},on:{"click":function($event){_vm.$emit('edit', true), _vm.edit = true}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(_vm.setEdit && _vm.edit)?_c('v-btn',{attrs:{"small":"","relative":"","text":"","icon":""},on:{"click":function($event){_vm.$emit('edit', false), _vm.edit = false}}},[_c('v-icon',[_vm._v("cancel")])],1):_vm._e(),(_vm.editDialog && _vm.aic)?_c('v-btn',{attrs:{"color":"blue","text":"","icon":""},on:{"click":function($event){_vm.showDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.print && _vm.printItems)?_c('v-btn',{staticStyle:{"z-index":"99999"},attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showPrint = !_vm.showPrint}}},[_c('v-icon',[_vm._v("print")])],1):_vm._e()],1),_c('v-dialog',{attrs:{"leave-absolute":"true","max-width":"450px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('BaseEditor',{attrs:{"query":_vm.edit,"aic":_vm.aic,"fieldCssClass":"md12","showTitle":false,"showDeleteButton":false},on:{"back":function($event){_vm.showDialog = false}}})],1),_c('v-dialog',{attrs:{"leave-absolute":"true","max-width":"450px","scrollable":""},model:{value:(_vm.showPrint),callback:function ($$v) {_vm.showPrint=$$v},expression:"showPrint"}},[_c('v-card',[_c('v-list',_vm._l((_vm.printItems),function(printItem,ux){return _c('v-list-item',{key:'ui' + ux,on:{"click":function($event){return _vm.openDruckVorschau(printItem)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(printItem.bezeichnung)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(printItem.tooltip))])])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pa-0",attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.showPrint = false}}},[_c('v-icon',[_vm._v("close")]),_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Close")))],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }