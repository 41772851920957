import { Vue } from "vue-property-decorator";
import { Dictionary } from "lodash";
import { Globals } from "@/Globals";
import Local from "@/local";
import { setRootView } from "./NavigationHelper";

export interface MenuEntry {
  site: string; // name der Komponetne
  name?: string;
  title?: string; // default titel wird ersetzt wenn name in Begriff da ist.
  icon?: string;
  subsites?: MenuEntry[];
  recht?: string;
}

export interface MenuMapping {
  rootMask: MenuEntry;
  sideMenu: MenuEntry[];
  defaultParameter: { [key: string]: any };
}

export default class RoleMenuMapping extends Vue {
  private static mapping: { [role: string]: MenuMapping } = {
    WebWorkflow: {
      rootMask: {
        site: "SoftTermSoftTerm",
        name: "SoftTerm"
      },
      sideMenu: [
        // Workflow, Welcome, Planung und Reisen
        {
          site: "SoftTermSoftTerm",
          name: "SoftTerm",
          icon: "watch_later",
          subsites: [
            {
              site: "SoftTermSoftTerm",
              name: "SoftTerm",
              icon: "watch_later"
            },
            {
              site: "SoftTermBuchungen",
              name: "Buchungen",
              icon: "list"
            },
            {
              site: "SoftTermAnwesenheiten",
              name: "Anwesenheiten",
              icon: "people",
            },
            {
              site: "PzePersoenlichWfWorkflowMa",
              name: "WorkflowMA",
              icon: "verified_user"
            },
            {
              site: "PzePersoenlichPlanungenAbwesenheiten",
              name: "Workflow_MA_Planung",
              icon: "event"
            },
            {
              site: "PzeWfVorgesetzterFreigabenAbwesenheitenMitarbeiter",
              name: "WF_Abwesenheiten_Abteilung",
              icon: "view_agenda"
            }
          ]
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
    WebEntwickler: {
      rootMask: {
        site: "AllWelcome",
        name: "Web_Willkommen",
        title: "Willkommen"
      },
      sideMenu: [
        {
          site: "PzePersoenlichPlanungenAbwesenheiten",
          name: "Workflow_MA_Planung",
          icon: "event"
          // subsites: [{ site: "UnderConstruction", name: "Sub 2", icon: "pets" }]
        },
        {
          site: "TestVuetifyCalendar",
          name: "Vuetify - Calendar Test",
          icon: "event"
        },
        {
          site: "PzeWorkflowWorkflowGrid",
          name: "Web_Workflow_MA",
          icon: "view_list"
        },
        {
          site: "SoftTermSoftTerm",
          name: "SoftTerm",
          icon: "watch_later",
          subsites: [
            {
              site: "SoftTermSoftTerm",
              name: "SoftTerm",
              icon: "watch_later"
            },
            {
              site: "SoftTermBuchungen",
              name: "Buchungen",
              icon: "list"
            },
            {
              site: "SoftTermAnwesenheiten",
              name: "Anwesenheiten",
              icon: "people"
            },
            {
              site: "SoftTermSalden",
              name: "Salden",
              icon: "trending_up",
              recht: "Def"
            },
            {
              site: "PzePersoenlichWfWorkflowMa",
              name: "WorkflowMA",
              icon: "verified_user"
            }
          ]
        },
        {
          site: "PzeWfVorgesetzterFreigabenAbwesenheitenMitarbeiter",
          name: "WF_Abwesenheiten_Abteilung",
          icon: "view_agenda",
          subsites: [
            {
              site: "PzeWfVorgesetzterFreigabenAbwesenheitenMitarbeiter",
              name: "WF_Abwesenheiten_Abteilung",
              icon: "event"
            },
            {
              site: "DashboardAufgabenTemplatedPage",
              name: "Web_DynDashboardVG",
              icon: "dashboard",
              recht: "Def",
            },
            {
              site: "DashboardAufgabenTemplatedPagePers",
              name: "Web_DynDashboardMA",
              icon: "dashboard",
              recht: "Def",
            },
          ]
        },
        {
          site: "RkMitarbeiterPersReisen",
          name: "WebWFPersReisen",
          icon: "work",
          subsites: [
            {
              site: "RkMitarbeiterPersReisen",
              name: "WebWFPersReisen",
              icon: "work",
            },
            {
              site: "RkMitarbeiterReisePlanung",
              name: "WebWFPersReisen",
              icon: "event"
            },
          ]
        },
        {
          site: "StammPerson",
          name: "Web_StammdatenMA",
          icon: "perm_identity"
        },
        {
          site: "StammOrt",
          name: "StammOrt",
          icon: "location_city"
        },
        {
          site: "StammAuftragDetail",
          name: "StammAuftrag",
          icon: "all_inbox"
        },
        {
          site: "StammFirma",
          name: "Web_RK_Stammdaten_Firma",
          icon: "store"
        },
        {
          site: "PzeAdminTreeViewMa",
          name: "HS_Admin",
          icon: "vertical_split",
          recht: "Def"
        },
        // diese Masken dürfen nur mit Def Berechtigung geladen werden
        {
          site: "SoftTermSoftTerm",
          name: "SoftTerm",
          icon: "grade",
          recht: "Def",
          subsites: [
            { site: "Demo", name: "Demo", icon: "build", recht: "Def" },
            {
              site: "TestVuetifyDataIterator",
              name: "Vuetify DataIterator",
              icon: "event",
              recht: "Def"
            },
            {
              site: "BaseZeitraum",
              name: "Zeitraum",
              icon: "calendar_today",
              recht: "Def"
            },
            {
              site: "TestGridSubTable",
              name: "SubTable Sync Grid",
              recht: "Def"
            },
            {
              site: "TestTestSchedule",
              name: "Schedule Test",
              recht: "Def"
            },
            {
              site: "TestWebTestSpeichernBase",
              name: "BaseSave - nur DEMO DB",
              recht: "Def"
            },
            {
              site: "TestInfo",
              name: "Info - Bsp VCalendar",
              icon: "trending_up",
              recht: "Def"
            },
            {
              site: "BaseSecurity",
              name: "Security",
              icon: "eject",
              recht: "Def"
            },
            {
              site: "BaseBenutzerMitarbeiter",
              name: "Benutzer",
              icon: "manage_accounts",
              recht: "Def"
            },
            {
              site: "TestStempelungenSync",
              name: "Sync Grid Test",
              recht: "Def"
            },
          ]
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
    Anwesenheiten: {
      rootMask: {
        site: "SoftTermAnwesenheiten",
        name: "Anwesenheiten"
      },
      sideMenu: [
        {
          site: "SoftTermAnwesenheiten",
          name: "Anwesenheiten",
          icon: "people"
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
    Buchungen: {
      rootMask: {
        site: "SoftTermBuchungen",
        name: "Buchungen"
      },
      sideMenu: [
        {
          site: "SoftTermBuchungen",
          name: "Buchungen",
          icon: "list"
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
    Web_Pers_Dashboard: {
      rootMask: {
        site: "DashboardAufgabenTemplatedPagePers",
        name: "Web_DynDashboardMA",
        icon: "dashboard",
      },
      sideMenu: [
        {
          site: "PzePersoenlichPlanungenAbwesenheiten",
          name: "Workflow_MA_Planung",
          icon: "event"
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
    // Workflow_MA_Planung: {
    //   rootMask: {
    //     site: "PzePersoenlichPlanungenAbwesenheiten",
    //     name: "Workflow_MA_Planung"
    //   },
    //   sideMenu: [
    //     {
    //       site: "PzePersoenlichPlanungenAbwesenheiten",
    //       name: "Workflow_MA_Planung",
    //       icon: "event"
    //     }
    //   ],
    //   defaultParameter: {
    //     irgendwasWasDerUserImmerBraucht: true
    //   }
    // },
    // Web - Vorgesetzter DS
    WebVorgesetzter: {
      rootMask: {
        site: "DashboardAufgabenTemplatedPage",
        name: "Web_DynDashboardVG",
        icon: "dashboard",
      },
      sideMenu: [
        {
          site: "DashboardAufgabenTemplatedPage",
          name: "Web_DynDashboardVG",
          icon: "dashboard",
        }
      ],
      defaultParameter: {
        irgendwasWasDerUserImmerBraucht: true
      }
    },
  };

  public static InitializeAppByRole(user: any, app: Vue): void {
    // const userRole: string = user.startFormular;
    let userRole: string = "";
    const text = "WebWorkflow";
    const userArray: any[] = user.startArray;
    if (userArray?.length > 0) {
      const workflow = userArray.find(sn => sn === text);
      if (workflow) {
        userRole = "WebWorkflow";
      } else {
        userRole = userArray[0];
      }
    }
    if (!userRole) {
      return;
    } else {
      console.log("logging in role: " + userRole);
      if (this.mapping[userRole]) {
        const map = this.mapping[userRole];
        setRootView(map.rootMask);
        // app.$root.$emit("setRootView", map.rootMask);
        console.log("userrecht:" + user.rechte);
        for (let i = 0; i < map.sideMenu.length; i++) {
          const entry = JSON.parse(JSON.stringify(map.sideMenu[i])); // clone um nicht original zu verändern

          if (
            !RoleMenuMapping.checkBegriffForEntry(app, entry) ||
            (entry.recht && user.rechte !== entry.recht)
          ) {
            continue;
          }

          if (entry.subsites) {
            entry.subsites = entry.subsites.filter(
              (sub: MenuEntry) =>
                RoleMenuMapping.checkBegriffForEntry(app, sub) ||
                (sub.recht && user.rechte === sub.recht)
            );
          }
          console.log("addmenu ", entry);
          app.$root.$emit("addMenu", entry);
        }
      }
    }

    return;
  }

  // checkt ob ein MenuEintrag für den User zugänglich ist oder nicht.
  private static checkBegriffForEntry(app: any, entry: MenuEntry) {
    if (entry.name) {
      // gibt es einen namen - dann gibt es auch den Begriff - dann darf ich anzeigen!
      const begriff: any = app.$globalsKennung(
        Globals.Begriff,
        entry.name,
        false
      );

      if (!begriff) {
        return false;
      }
    }
    return true;
  }
}
