















































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import {
  getKZValueDisplay,
  getKZValue,
  getKennungTitle,
  getKennungValueDisplay
} from "@/DataHelper";
import { getMonthNames } from "@/editor/CalenderHelper";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
import BaseEditor from "@/components/BaseEditor.vue";
import { calcFunctionNew } from "@/CalcHelperStatic";
import { DialogParameter } from "@/UIHelper";

@Component
export default class AnzeigeReisenTimeLine extends CreateList {
  constructor() {
    super();
    this.queryMutable = "Web_ReiseUebersichtBuchungen";
    this.showLoadingReise = true;
  }
  private reveal: boolean = false;
  private reveal1: boolean = false;
  private showAdresse: boolean = false;
  private reiseClass: string = "grey lighten-2 pa-0 ml-2";
  private showLoadingReise: boolean = false;
  private sperre: boolean = false;
  private kosten: string = "";
  private showMemo: boolean = false;
  private tooltip: string = "";
  private tooltip1: string = "";
  private modell: string = "RK_WebReiseabrechnungVollstaendig";
  private modell1: string = "RK_Web_ReiseZurueckZiehen";
  private anzahlTage: number = 0;
  @Prop({ default: 450 }) public width!: number;
  public mounted() {
    const begriffModell = this.$globalsKennung(Globals.Begriff, this.modell);
    if (begriffModell) {
      this.tooltip = begriffModell.tooltip;
    }
    const begriffModell1 = this.$globalsKennung(Globals.Begriff, this.modell1);
    if (begriffModell1) {
      this.tooltip1 = begriffModell1.tooltip;
    }
    this.mountedBase();
  }

  private initalized() {
    // this.width = this.width + 10;
    const adresse = getKennungTitle("ADDRESSEE", this.columns);
    if (adresse) {
      this.showAdresse = true;
    }
    this.sperre = getKZValue(this.data, "sperre", this.columns);
  }
  private onBack() {
    this.reveal = false;
    this.reloadData();
  }
  private back() {
    this.reveal1 = false;
    this.reloadData();
  }
  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
  }
  protected refreshItems() {
    this.items = [];
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      const kosten = getKZValueDisplay(row, "kosten", this.columns);
      const km = getKZValueDisplay(row, "km", this.columns);
      const belege = getKZValueDisplay(row, "belege", this.columns);
      const diaten = getKZValueDisplay(row, "diaten", this.columns);
      const sperre = getKZValue(row, "sperre", this.columns); // Status !== 100
      const ende = getKZValueDisplay(row, "Ende", this.columns);
      const endeDate = getKZValue(row, "Ende", this.columns);
      this.showMemo = getKZValue(row, "showMemo", this.columns);
      if (ende && !sperre) {
        this.showCalc = true;
      }
      let abgeschlossen = getKZValue(row, "abgeschlossen", this.columns); // monat LV = Übergleitet
      let textAbg = "";
      if (abgeschlossen) {
        abgeschlossen = new Date(abgeschlossen);
        textAbg = getMonthNames()[abgeschlossen.getMonth()];
      }
      let title = getKZValueDisplay(row, "subject", this.columns);
      const title2 = getKZValueDisplay(row, "Beginn", this.columns);
      const subtitel = getKZValueDisplay(row, "Ende", this.columns);
      const status = getKZValueDisplay(row, "status", this.columns);
      const anzahlTage = getKZValue(row, "Tage", this.columns);

      let cssClass = "text--white";

      if (abgeschlossen) {
        cssClass = "text--success";
        title = title + " (bezahlt: " + textAbg + ")";
      } else if (status) {
        cssClass = "text--info";
        title = title + " (" + status + ")";
      }
      const memo = getKZValueDisplay(row, "memo", this.columns);
      const kostenstelle = getKennungValueDisplay(
        row,
        "KOSTENSTELLENZUORDNG",
        this.columns
      );
      this.items.push({
        title,
        title2,
        subtitel,
        divider: true,
        inset: true,
        aic: row.aic_Stamm,
        menu: false,
        flex: 12,
        memo,
        kostenstelle,
        cssClass,
        sperre,
        number: anzahlTage,
        subtitel1: kosten,
        expand: false,
        select: false,
        date: endeDate,
        subtitelHeader: belege,
        differenz: km,
        differenzValue: diaten
      });
      this.showLoadingReise = false;
    }
  }
  private openKostenersatz(
    nameReise: string,
    aicReise: number,
    sperre: boolean,
    anzahlTage: number,
    endeDatum: Date
  ) {
    let showFullscreen = false;
    if (Constants.isMobile && this.$isPhone()) {
      showFullscreen = true;
    }
    let sperreNacht = false;
    if (this.editZeitbereich) {
      const von = this.editZeitbereich.von;
      const end = new Date(endeDatum);
      if (end.valueOf() === von.valueOf()) {
        sperreNacht = true;
      }
    }

    const me = this;
    const navData = {
      zeitbereich: this.editZeitbereich,
      nameReise,
      aicReise,
      sperre,
      anzahlTage,
      onReloadData: false,
      sperreNacht
    };
    onShowSite({
      title: this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Reise",
        "Reise bearbeiten"
      ),
      site: "RkMitarbeiterReiseUebersicht",
      data: navData,
      callOnLeave: () => {
        if (navData.onReloadData === true) {
          if (anzahlTage !== 0) {
            setTimeout(() => refreshFullPageContent());
          } else {
            this.reloadData();
          }
        }
      }
    });
  }
  private onCalcFunction(modell: string, aic: number, anzahlTage: number) {
    const me = this;
    if (modell) {
      calcFunctionNew(modell, aic, false, me, this.editZeitbereich, []);
      this.onCalculated();
      if (anzahlTage !== 0) {
        setTimeout(() => refreshFullPageContent());
      }
    }
  }
  private onCalculated() {
    // this.reveal1 = false;
    let editor: BaseEditor | undefined;
    const x = this.$refs.statusInfo as Vue[];
    if (x.length > 0) {
      editor = x[0] as BaseEditor;
    }
    editor?.reloadData();
    this.reloadData();
  }
}
